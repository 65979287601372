import _ from 'lodash';
import React, { useState } from 'react';
import { Form as SemanticForm, Dropdown } from 'semantic-ui-react';
import { FormFieldProp } from '../../../enum/apiResultProp/FormFieldProp';

export default ({ fieldProps, onValueChange, onInputBlur }) => {
    const [open, setOpen] = useState(false);


    const value = fieldProps[FormFieldProp.Value];
    const placeholder = fieldProps[FormFieldProp.Placeholder];
    const showLabel = fieldProps[FormFieldProp.ShowLabel];
    const label = fieldProps[FormFieldProp.Label];
    const hasError = fieldProps[FormFieldProp.HasError];
    const showMandatorySign = fieldProps[FormFieldProp.ShowMandatorySign];
    const isReadOnly = fieldProps[FormFieldProp.IsReadOnly];
    const options = fieldProps[FormFieldProp.Options];

    let dropdownProps = {
        selectOnBlur: false,
        options: options,
        required: showMandatorySign,
        readOnly: isReadOnly,
        disabled: isReadOnly,
        error: hasError,
        placeholder: placeholder,
        value: value,
        onChange: async (e, { value }) => {
            await onValueChange({ value: value });
            onInputBlur({ value: value });
        },
        onKeyPress: e => {
            if (e.key === 'Enter') {
                e.preventDefault();
            }
        },
        // onBlur: () => onInputBlur({ value: value })
    };
    if (showLabel) {
        dropdownProps = { ...dropdownProps, label: label };
    }


    const disabled = isReadOnly;
    let style = {
        display: 'inline-block',
        fontSize: '12px',
        minWidth: '200px',
        padding: '9px',
        border: '1px solid',
        borderColor: 'rgba(34,36,38,.15)',
        borderRadius: '4px',
        background: '#fff',
        cursor: 'pointer',
        boxShadow: 'none',
        outline: 'none',
        transition: 'box-shadow 0.1s ease, border-color 0.1s ease',

        overflow: 'hidden',
        whiteSpace: 'pre',
        textOverflow: 'ellipsis',
    };
    if (disabled) {
        style.backgroundColor = '#EEEEEE';
    }

    return (
        <select
            onChange={async (e) => {
                await onValueChange({ value: e.target.value });
                onInputBlur({ value: e.target.value });
            }}
            disabled={disabled}
            value={value}
            style={style}
        >
            <option value="" disabled></option>
            {_.map(options, ({ key, value, text }) => <option key={key} value={value}>{text}</option>)}
        </select>
    );
}