import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import history from '../history';
import { checkAuth, logout } from '../actions/authActions';
import { toggleVisibility, openPromptModal, closePromptModal } from '../actions/notificationBar/actions';
import { openModal as openDocumentationModal } from '../actions/appDocumentation/actions';
import bfmLogo from '../resources/bfm.png';

import { ROUTES } from '../helpers/routes';
import { getValue } from '../helpers/functions';
import { STRINGS } from '../helpers/strings';
import { Button, Menu, Dropdown, Icon, Label, Modal, Flag, DropdownMenu, DropdownItem } from 'semantic-ui-react';
import { ScreenProp } from '../enum/apiResultProp/ScreenProp';

class Header extends React.Component {
    componentDidMount() {
        this.props.checkAuth();
    }

    onLogoutClick = () => {
        this.props.logout();
    }

    renderPromptModal = () => {
        const { visible, linkRouteName, linkRouteUrl, linkName } = this.props.onLinkClickPromptModal;

        return (
            <Modal
                centered={false}
                open={visible}
            >

                <Modal.Content>
                    <p>{getValue(this.props.translations, STRINGS.YOUR_DATA_IS_NOT_SAVED)} {linkName}?</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic color='red' onClick={this.props.closePromptModal}>
                        <Icon name='remove' /> No
                    </Button>
                    <Button basic color='green' onClick={async () => {
                        await this.props.closePromptModal();
                        history.push(linkRouteUrl);
                    }}>
                        <Icon name='checkmark' /> Yes
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }

    renderMainMenu = () => {
        const { mainMenu, screenHasInputFields } = this.props;
        if (mainMenu === undefined) {
            return null;
        }

        return (
            <>
                {_.map(mainMenu, (item, itemKey) => {
                    const { type, label, icon, clientRouteName, items } = item;

                    if (type === 'ITEM') {
                        const url = ROUTES[clientRouteName].URL;

                        return (
                            <Menu.Item
                                key={itemKey}
                                onClick={() => {
                                    if (screenHasInputFields) {
                                        this.props.openPromptModal(clientRouteName, url, label);
                                    } else {
                                        history.push(url);
                                    }
                                }}
                            >
                                <Icon name={icon} />
                                {label}
                            </Menu.Item>
                        )
                    } else if (type === 'DROPDOWN_MENU') {
                        return (
                            <Dropdown item text={label} key={itemKey}>
                                <DropdownMenu>
                                    {
                                        _.map(items, (nestedItem, nestedItemKey) => {
                                            const nestedUrl = ROUTES[nestedItem.clientRouteName] ? ROUTES[nestedItem.clientRouteName].URL : '';

                                            return (
                                                <DropdownItem
                                                    key={nestedItemKey}
                                                    icon={nestedItem.icon}
                                                    text={nestedItem.label}
                                                    onClick={() => {
                                                        if (screenHasInputFields) {
                                                            this.props.openPromptModal(nestedItem.clientRouteName, nestedUrl, nestedItem.label);
                                                        } else {
                                                            history.push(nestedUrl);
                                                        }
                                                    }}
                                                />
                                            );
                                        })
                                    }
                                </DropdownMenu>
                            </Dropdown>
                        );
                    }
                })}
            </>
        );
    }

    renderNotificationLabel = () => {
        const { totalCountOfNotifications, unreadCountOfNotifications } = this.props;

        let iconColor = 'white';
        let size = '';
        if (unreadCountOfNotifications > 0) {
            iconColor = 'red';
            size = 'small';
        } else {
            iconColor = 'white';
            size = 'small';
        }

        return (
            <Label as='a' style={{ backgroundColor: '#2887e6' }} onClick={this.props.toggleVisibility} size={size}>
                <Icon name='bell' style={{ color: iconColor }} /> {unreadCountOfNotifications} / {totalCountOfNotifications}
            </Label>
        );
    }

    renderLogo = () => {
        if (process.env.REACT_APP_LOGO_NAME === 'BFM') {
            return (
                <img alt="BFM" className="ui image" src={bfmLogo} style={{ maxHeight: "50px" }} />
            );
        }

        return null;
    }

    render = () => {
        const { screenHasInputFields, isLoggedIn, showLiveWarning } = this.props;

        if (!isLoggedIn) {
            return (
                <Menu style={{ marginBottom: '0px', borderRadius: '0px' }}>
                    <Menu.Item>
                        {this.renderLogo()}
                    </Menu.Item>
                </Menu>
            );
        }

        let liveWarningProps = {};
        if (showLiveWarning) {
            liveWarningProps.color = 'red';
            liveWarningProps.inverted = true;
        }

        return (
            <Menu style={{ marginBottom: '0px', borderRadius: '0px' }} {...liveWarningProps}>
                {this.renderPromptModal()}

                <Menu.Item
                    onClick={() => {
                        if (screenHasInputFields) {
                            this.props.openPromptModal(ROUTES.HOME_SCREEN_ROUTE.NAME, ROUTES.HOME_SCREEN_ROUTE.URL, 'Home Screen');
                        } else {
                            history.push(ROUTES.HOME_SCREEN_ROUTE.URL);
                        }
                    }}
                >
                    {this.renderLogo()}
                </Menu.Item>

                {this.renderMainMenu()}

                <Menu.Menu position='right' >
                    <Menu.Item>
                        {this.renderNotificationLabel()}
                    </Menu.Item>
                    <Dropdown
                        item
                        trigger={(
                            <span>
                                <Flag name={'bg'} /> {this.props.userName}
                            </span>
                        )}
                    >
                        <Dropdown.Menu>
                            <Dropdown.Item
                                key={ROUTES.CHANGE_PASSOWRD_ROUTE.NAME}
                                onClick={() => {
                                    if (screenHasInputFields) {
                                        this.props.openPromptModal(ROUTES.CHANGE_PASSOWRD_ROUTE.NAME, ROUTES.CHANGE_PASSOWRD_ROUTE.URL, 'Промяна на Парола');
                                    } else {
                                        history.push(ROUTES.CHANGE_PASSOWRD_ROUTE.URL);
                                    }
                                }}
                            >
                                <Icon name='key' />
                                Промяна на Парола
                            </Dropdown.Item>
                            <Dropdown.Item
                                key={ROUTES.CHANGE_EMAIL_ROUTE.NAME}
                                onClick={() => {
                                    if (screenHasInputFields) {
                                        this.props.openPromptModal(ROUTES.CHANGE_EMAIL_ROUTE.NAME, ROUTES.CHANGE_EMAIL_ROUTE.URL, 'Промяна на Имейл');
                                    } else {
                                        history.push(ROUTES.CHANGE_EMAIL_ROUTE.URL);
                                    }
                                }}

                            >
                                <Icon name='mail' />
                                Промяна на Имейл
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Menu.Item>
                        {/* <Button
                            onClick={this.onLogoutClick}
                            fluid
                            size='tiny'
                            color='red'
                        >
                            {getValue(this.props.translations, STRINGS.BUTTON_LOGOUT)}
                        </Button> */}
                        <Button icon color='blue' onClick={this.onLogoutClick}>
                            <Icon name='log out' style={{ color: 'white' }} />
                        </Button>
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { isLoggedIn, userName, countryIsoCode, mainMenu, translations, showLiveWarning } = state.auth;
    const { countTotal, countUnread, isInCreateEditPage, onLinkClickPromptModal } = state.notificationBar;
    const screenHasInputFields = state.screenData[ScreenProp.HasInputFields];

    return {
        userName, countryIsoCode, isInCreateEditPage, onLinkClickPromptModal, isLoggedIn, mainMenu, translations,
        totalCountOfNotifications: countTotal, unreadCountOfNotifications: countUnread, screenHasInputFields, showLiveWarning
    };
}

export default connect(mapStateToProps, { checkAuth, logout, toggleVisibility, openPromptModal, closePromptModal, openDocumentationModal })(Header);
