import React from 'react';
import _ from 'lodash';
import { Table } from 'semantic-ui-react';
import { TableFormDataRowProp } from '../../../../enum/apiResultProp/TableFormDataRowProp';
import { TableDataRowColor } from '../../../../enum/TableDataRowColor';
import AbstractTableFormCell from '../cell/AbstractTableFormCell'

export default ({
    tableDataRowProps, rowKey, onInputFieldChange, updateInputFieldErrorAction, onActionDownload,
    onButtonClickMutateScreenWithPostData, onFieldChangeMutateScreenWithPostData, onFieldChangeMutateComponentWithPostData,
    openModalForm
}) => {
    const cells = tableDataRowProps[TableFormDataRowProp.Cells];
    const color = tableDataRowProps[TableFormDataRowProp.Color];
    // const uniqueRowId = tableDataRowProps[TableFormDataRowProp.UniqueRowId];

    let warning = false;
    let positive = false;
    let negative = false;

    if (color === TableDataRowColor.Orange) {
        warning = true;
    } else if (color === TableDataRowColor.Red) {
        negative = true;
    } else if (color === TableDataRowColor.Green) {
        positive = true;
    }

    return (
        <Table.Row warning={warning} positive={positive} negative={negative}>
            {_.map(cells, (cellProps, cellKey) => {

                return (
                    <AbstractTableFormCell
                        key={cellKey}
                        rowKey={rowKey}
                        cellProps={cellProps}
                        onInputFieldChange={(props) => onInputFieldChange({ ...props, cellKey })}
                        updateInputFieldErrorAction={(props) => updateInputFieldErrorAction({ ...props, cellKey })}
                        onActionDownload={onActionDownload}
                        onButtonClickMutateScreenWithPostData={onButtonClickMutateScreenWithPostData}
                        onFieldChangeMutateScreenWithPostData={onFieldChangeMutateScreenWithPostData}
                        onFieldChangeMutateComponentWithPostData={onFieldChangeMutateComponentWithPostData}
                        openModalForm={openModalForm}
                    />
                );
            })}
        </Table.Row>
    );
}