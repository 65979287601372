import React from 'react';
import _ from 'lodash';
import '../../resources/style/SqlViewer.css';
import { Segment } from 'semantic-ui-react';

// const data = {
//     tmpTable: {},
//     distinct: {},
//     selectFields: {
//         f_1: {
//             pos: 2,
//             table: 'o',
//             field: 'doc_number',
//             alias: 'offer_doc_number',
//         },
//         f_2: {
//             pos: 1,
//             table: 'o',
//             field: 'created_by',
//             alias: 'offer_doc_number',
//         },
//     },
//     fromTable: {
//         tableName: 'offers',
//         alias: 'o',
//     },
//     joinTables: {
//         jt_1: {
//             pos: 1,
//             join: 'INNER JOIN',
//             tableName: 'users',
//             tableAlias: 'u',
//             conditions: {
//                 c_1: {
//                     pos: 1,
//                     logicOperator: '',
//                     comparatorOperator: '=',
//                     leftSequence: {
//                         type: 'tableField', //'intValue', 'stringValue', 'boolValue'
//                         table: 'u',
//                         field: 'id',
//                     },
//                     rightSequence: {
//                         type: 'tableField',
//                         table: 'o',
//                         field: 'created_by',
//                     },
//                 },
//             }
//         },
//         jt_2: {
//             pos: 1,
//             join: 'LEFT JOIN',
//             tableName: 'work_centers',
//             tableAlias: 'u',
//             conditions: {
//                 c_1: {
//                     pos: 1,
//                     logicOperator: '',
//                     comparatorOperator: '=',
//                     leftSequence: {
//                         type: 'tableField',
//                         table: 'wc',
//                         field: 'id',
//                     },
//                     rightSequence: {
//                         type: 'tableField',
//                         table: 'o',
//                         field: 'work_center_id',
//                     },
//                 },
//                 c_2: {
//                     pos: 2,
//                     logicOperator: 'AND',
//                     comparatorOperator: '!=',
//                     leftSequence: {
//                         type: 'tableField',
//                         table: 'u',
//                         field: 'id',
//                     },
//                     rightSequence: {
//                         type: 'nullValue',
//                         value: 'NULL'
//                     },
//                 },
//             }
//         }
//     },
//     whereConditions: {
//         wc_1: {
//             pos: 1,
//             logicOperator: '',
//             comparatorOperator: '=',
//             leftSequence: {
//                 type: 'tableField', //'intValue', 'stringValue', 'boolValue'
//                 table: 'u',
//                 field: 'id',
//             },
//             rightSequence: {
//                 type: 'intValue',
//                 value: 4,
//             },
//         },
//         wc_2: {
//             pos: 1,
//             logicOperator: ' AND ',
//             comparatorOperator: '=',
//             leftSequence: {
//                 type: 'tableField', //'intValue', 'stringValue', 'boolValue'
//                 table: 'u',
//                 field: 'user_name',
//             },
//             rightSequence: {
//                 type: 'stringValue',
//                 value: 'SysAdmin',
//             },
//         }
//     },
//     orderClauses: {
//         oc_1: {
//             pos: 1,
//             table: 'o',
//             field: 'doc_number',
//             direction: 'DESC'
//         },
//         oc_2: {
//             pos: 2,
//             table: 'u',
//             field: 'id',
//         },
//     }
// };

const SqlViewer = ({ data }) => {
    const selectWord = 'SELECT';
    const fromWord = 'FROM';
    const whereWord = 'WHERE';
    const orderWord = 'ORDER BY';
    const asWord = 'AS';
    const onWord = 'ON';
    const concatOperator = '||';
    const dotOperator = '.';
    const commaOperator = ',';
    const openBracket = '(';
    const closeBracket = ')';
    const singleQuote = "'";
    const space = ' ';
    // const tab = '   ';
    const placeholderSymbol = ':';

    const renderNewLine = () => <br />;

    const renderSelectFields = (selectFields) => {
        const selectFieldsSorted = _.sortBy(selectFields, 'pos');

        const fieldElements = [];
        let lastIndex = null;
        _.forEach(selectFieldsSorted, (fieldObj, index) => {
            const { table, field, alias, type } = fieldObj;

            let fieldHtml = null;
            if (type === 'field') {
                fieldHtml = (
                    <span>
                        <span className='sql-view-tableName'>{table}</span>
                        <span>{dotOperator}</span>
                        <span className='sql-view-fieldName'>{field}</span>

                        {alias
                            ? (
                                <>
                                    <span className='sql-view-sqlWord'>{space}{asWord}{space}</span>
                                    <span className='sql-view-aliasName'>{alias}</span>
                                </>
                            )
                            : null
                        }
                    </span>
                );
            } else if (type === 'concatFields') {
                const { concatFields, glueString } = fieldObj;

                const concatFieldsSorted = _.sortBy(concatFields, 'pos');

                let isFirstField = true;
                fieldHtml = (
                    <span>
                        <span>{openBracket}</span>

                        {
                            _.map(concatFieldsSorted, (concatField, concatFieldIndex) => {
                                let concatSymbolAndGlue = null;
                                if (isFirstField) {
                                    isFirstField = false;
                                } else {
                                    concatSymbolAndGlue = (
                                        <span>
                                            {glueString
                                                ? (
                                                    <span>
                                                        <span>{space}{concatOperator}{space}</span>
                                                        <span className='sql-view-stringWord'>{singleQuote}{glueString}{singleQuote}{space}</span>
                                                    </span>
                                                )
                                                : null
                                            }
                                            <span>{space}{concatOperator}{space}</span>
                                        </span>
                                    );
                                }

                                return (
                                    <span key={concatFieldIndex}>
                                        {concatSymbolAndGlue}
                                        <span className='sql-view-tableName'>{concatField.table}</span>
                                        <span>{dotOperator}</span>
                                        <span className='sql-view-fieldName'>{concatField.field}</span>
                                    </span>
                                );
                            })
                        }

                        <span>{closeBracket}</span>

                        {alias
                            ? (
                                <>
                                    <span className='sql-view-sqlWord'>{space}{asWord}{space}</span>
                                    <span className='sql-view-aliasName'>{alias}</span>
                                </>
                            )
                            : null
                        }
                    </span>
                );
            }

            fieldElements[index] = fieldHtml;
            lastIndex = index;
        });

        const result = (
            <div className='sql-view-select-clause-wrapper'>
                {_.map(fieldElements, (element, index) => {
                    return (
                        <div key={index}>
                            {element}
                            {index !== lastIndex
                                ? (
                                    <>
                                        <span>{commaOperator}</span>
                                        {renderNewLine()}
                                    </>
                                )
                                : (
                                    <>
                                        {renderNewLine()}
                                    </>
                                )
                            }
                        </div>
                    )
                })}
            </div>
        );

        return result;
    }

    const renderFrom = (fromTable) => {
        const { tableName, tableAlias } = fromTable;

        return (
            <span className='sql-view-from-clause-wrapper'>
                <span className='sql-view-tableName'>{tableName}</span>
                {tableAlias
                    ? (
                        <>
                            <span className='sql-view-sqlWord'>{space}{asWord}{space}</span>
                            <span className='sql-view-aliasName'>{tableAlias}</span>
                        </>
                    )
                    : null
                }
            </span>
        );
    }

    const renderSingleSequence = ({ type, table, field, value, sqlObject }) => {
        switch (type) {
            case 'tableField':
                return (
                    <>
                        <span className='sql-view-tableName'>{table}</span>
                        <span>{dotOperator}</span>
                        <span className='sql-view-fieldName'>{field}</span>
                    </>
                );
            case 'intValue':
                return (
                    <span>{value}</span>
                );
            case 'stringValue':
                return (
                    <span className='sql-view-stringWord'>
                        <span>{singleQuote}</span>
                        <span>{value}</span>
                        <span>{singleQuote}</span>
                    </span>
                );
            case 'boolValue':
                return (
                    <span className='sql-view-sqlWord'>{value}</span>
                );
            case 'nulValue':
                return (
                    <span className='sql-view-sqlWord'>{value}</span>
                );
            case 'alias':
                return (
                    <span>{value}</span>
                );
            case 'placeholder':
                return (
                    <span>{placeholderSymbol}{value}</span>
                );
            case 'nestedQuery':
                return (
                    <span>
                        <span>{space}{openBracket}</span>
                        {renderNewLine()}
                        <div className='sql-view-select-nested-query-wrapper'>
                            {renderQuery(sqlObject)}
                        </div>
                        <span>{closeBracket}</span>
                    </span>
                );
            default:
                return (
                    <span>{value}</span>
                );
        }
    }

    const renderSingleCondition = ({ logicOperator, comparatorOperator, leftSequence, rightSequence }) => {
        const leftSequenceElem = renderSingleSequence(leftSequence);
        const rightSequenceElem = renderSingleSequence(rightSequence);

        return (
            <>
                {logicOperator
                    ? <span className='sql-view-sqlWord'>{logicOperator}{space}</span>
                    : null
                }
                {leftSequenceElem}
                <span className='sql-view-compareOperator'></span>{space}{comparatorOperator}{space}
                {rightSequenceElem}
            </>
        );
    }

    const renderJoinTables = (joinTables) => {
        if (!joinTables) {
            return null;
        }

        const joinTablesOrdered = _.orderBy(joinTables, 'pos');

        const joinElements = [];
        _.forEach(joinTablesOrdered, (joinElement, index) => {
            const { join, conditions, joinObjectType } = joinElement;

            //Join Clause conditions 
            let onClause = null;
            if (_.size(conditions) === 1) {
                const condition = _.find(conditions);

                let clauseCondition = null;
                if (condition.type === 'sequence') {
                    clauseCondition = renderSingleCondition(condition);
                } else if (condition.type === 'boolean') {
                    clauseCondition = <span>{condition.value}</span>;
                }

                onClause = (
                    <>
                        {renderNewLine()}
                        <span className='sql-view-onClause-wrapper'>
                            <span className='sql-view-sqlWord'>{space}{onWord}{space}</span>
                            <span className='sql-view-onClause-bracket'>{openBracket}</span>
                            {clauseCondition}
                            <span className='sql-view-onClause-bracket'>{closeBracket}</span>
                        </span>
                    </>
                );
            } else {
                const sortedConditions = _.sortBy(conditions, 'pos');

                onClause = (
                    <>
                        <span className='sql-view-sqlWord'>{space}{onWord}{space}</span>
                        <span className='sql-view-onClause-bracket'>{openBracket}</span>

                        {
                            _.map(sortedConditions, (condition) => {
                                return (
                                    <>
                                        {renderNewLine()}
                                        <span className='sql-view-onClause-wrapper'>
                                            {renderSingleCondition(condition)}
                                        </span>
                                    </>
                                );
                            })
                        }

                        {renderNewLine()}
                        <span className='sql-view-onClause-bracket'>{closeBracket}</span>
                    </>
                );
            }


            let joinElementHtml = null;
            if (joinObjectType === 'table') {
                const { tableName, tableAlias } = joinElement;

                joinElementHtml = (
                    <span>
                        <span className='sql-view-sqlWord'>{join}{space}</span>
                        <span className='sql-view-tableName'>{tableName}</span>

                        {tableAlias
                            ? (
                                <>
                                    <span className='sql-view-sqlWord'>{space}{asWord}{space}</span>
                                    <span className='sql-view-aliasName'>{tableAlias}</span>
                                </>
                            )
                            : null
                        }

                        {onClause}
                    </span>
                );
            } else if (joinObjectType === 'function') {
                const { funcName, funcParams, funcAlias } = joinElement;

                const funcParamsOrdered = _.orderBy(funcParams, 'pos');
                let isFirstFuncParam = true;

                joinElementHtml = (
                    <span>
                        <span className='sql-view-sqlWord'>{join}{space}</span>
                        <span className='sql-view-sqlFunc'>{funcName}</span>
                        <span>{openBracket}</span>
                        {
                            _.map(funcParamsOrdered, (funcParam, funcParamKey) => {
                                let funcParamSeparator = null;
                                if (isFirstFuncParam) {
                                    isFirstFuncParam = false;
                                } else {
                                    funcParamSeparator = <span>{commaOperator}{space}</span>;
                                }

                                return (
                                    <span key={funcParamKey}>
                                        {funcParamSeparator}
                                        <span className='sql-view-tableName'>{funcParam.tableName}</span>
                                        <span>{dotOperator}</span>
                                        <span className='sql-view-fieldName'>{funcParam.fieldName}</span>
                                    </span>
                                );
                            })
                        }
                        <span>{closeBracket}</span>

                        {funcAlias
                            ? (
                                <>
                                    <span className='sql-view-sqlWord'>{space}{asWord}{space}</span>
                                    <span className='sql-view-aliasName'>{funcAlias}</span>
                                </>
                            )
                            : null
                        }

                        {onClause}
                    </span>
                );
            }



            joinElements[index] = joinElementHtml;
        });

        const result = (
            <div className='sql-view-all-joins-wrapper'>
                {_.map(joinElements, (element, index) => {
                    return (
                        <div key={index}>
                            {element}
                            {renderNewLine()}
                        </div>
                    )
                })}
            </div>
        );

        return result;
    }

    const renderWhereConditions = (whereConditions) => {
        if (!whereConditions) {
            return null;
        }

        const whereConditionsOrdered = _.sortBy(whereConditions, 'pos');

        return (
            <>
                <span className='sql-view-sqlWord'>{whereWord}</span>
                {
                    _.map(whereConditionsOrdered, (whereCondition, index) => {
                        return (
                            <span key={index}>
                                {renderNewLine()}
                                <span className='sql-view-whereClause-wrapper'>
                                    {renderSingleCondition(whereCondition)}
                                </span>
                            </span>
                        )
                    })
                }
            </>
        );
    }

    const renderOrderClauses = (orderClauses) => {
        if (!orderClauses) {
            return null;
        }

        const orderClausesOrdered = _.sortBy(orderClauses, 'pos');
        let isFirstElement = true;
        return (
            <>
                {renderNewLine()}
                <span className='sql-view-sqlWord'>{orderWord}</span>
                {renderNewLine()}
                <span className='sql-view-orderClause-wrapper'>
                    {
                        _.map(orderClausesOrdered, (orderClause, index) => {
                            const { table, field, direction } = orderClause;

                            let separator = null;
                            if (!isFirstElement) {
                                separator = (
                                    <span>{commaOperator}{space}</span>
                                );
                            } else {
                                isFirstElement = false;
                            }

                            return (
                                <span key={index}>
                                    {separator}
                                    <span className='sql-view-tableName'>{table}</span>
                                    <span>{dotOperator}</span>
                                    <span className='sql-view-fieldName'>{field}</span>

                                    {direction
                                        ? <span className='sql-view-sqlWord'>{space}{direction}</span>
                                        : null
                                    }
                                </span>
                            )
                        })
                    }
                </span>
            </>
        );
    }

    const renderQuery = (sqlObject) => {
        const { selectFields, fromTable, joinTables, whereConditions, orderClauses } = sqlObject;

        return (
            <>
                <span className='sql-view-sqlWord'>{selectWord}</span>
                {renderNewLine()}
                {renderSelectFields(selectFields)}
                <span className='sql-view-sqlWord'>{fromWord}</span>
                {renderNewLine()}
                {renderFrom(fromTable)}
                {renderNewLine()}
                {renderJoinTables(joinTables)}
                {renderWhereConditions(whereConditions)}
                {renderOrderClauses(orderClauses)}
            </>
        );
    }

    return (
        <Segment basic>
            {renderQuery(data)}
        </Segment>
    );
}

export default SqlViewer;