import React from 'react';
import _ from 'lodash';
import { Table } from 'semantic-ui-react';
import { TableDataCellProp } from '../../../../enum/apiResultProp/TableDataCellProp';
import { ROUTES } from '../../../../helpers/routes';
import { ActionProp } from '../../../../enum/apiResultProp/ActionProp';
import history from '../../../../history';
import { ActionType } from '../../../../enum/ActionType';

export default ({ tableDataCellProps, onActionDownload }) => {
    // const columnName = tableDataCellProps[TableDataCellProp.ColumnName];
    // const posNumber = tableDataCellProps[TableDataCellProp.PositionNumber];
    const text = tableDataCellProps[TableDataCellProp.Text];
    const onClickAction = tableDataCellProps[TableDataCellProp.OnClickAction];

    let style = {};
    if (onClickAction) {
        style = { cursor: 'pointer', fontWeight: 'bold', };
    }

    const onClick = () => {
        if (onClickAction) {
            let actionType = onClickAction[ActionProp.ActionType];
            
            if (actionType === ActionType.RedirectFrontEnd) {
                let routeName = onClickAction[ActionProp.FrontEndRouteName];
                let routeParams = onClickAction[ActionProp.FrontEndRouteParams];

                let nextRoute = ROUTES.HOME_SCREEN_ROUTE.URL;
                if (ROUTES[routeName]) {
                    nextRoute = ROUTES[routeName].URL;
        
                    if (routeParams) {
                        _.forEach(routeParams, (paramValue, paramName) => {
                            nextRoute = nextRoute.replace(':' + paramName, paramValue);
                        });
                    }
                }
        
                history.push(nextRoute);
            } else if (actionType === ActionType.DownloadFileAction) {
                const backEndUrl = onClickAction[ActionProp.BackEndUrl];
                const fileNameOnDownload = onClickAction[ActionProp.FileNameOnDownload];
                
                onActionDownload({ backEndUrl, fileNameOnDownload });
            }
    
        }
    }

    return <Table.Cell onClick={onClick} style={style}>{text}</Table.Cell>;
}