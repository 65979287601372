import _ from "lodash";
import actionTypes from "../../actions/actionTypes";

const INITIAL_AUTH_STATE = {
    isLoggedIn: false,
    countryIsoCode: "BG",
    username: "",
    email: "",
    mustChangePassLogic: {
        mustChangePassword: false,
        warningMessages: [],
    },
    mainMenu: {},
    showLiveWarning: false,
    translations: {},
    settings: {},
    loginForm: {
        fields: {
            username: '',
            verificationCode: '',
            password: ''
        },
        message: {
            errorMessage: '',
            successMessage: '',
        },
        buttonIsInAction: {
            generateValidationCode: false,
            submitForm: false
        }
    },
    registrationForm: {
        fields: {
            email: '',
            verificationCode: '',
            password: '',
            confirmPassword: '',
            birthdate: '',
            externalUserType: ''
        },
        message: {
            errorMessage: '',
            successMessage: '',
        },
        buttonIsInAction: {
            generateValidationCode: false,
            submitForm: false
        }
    },
    forgotPassordForm: {
        fields: {
            username: '',
            verificationCode: '',
            password: '',
            confirmPassword: '',
        },
        message: {
            errorMessage: '',
            successMessage: '',
        },
        buttonIsInAction: {
            generateValidationCode: false,
            submitForm: false
        }
    },
    changeEmailForm: {
        fields: {
            newEmail: '',
            oldEmailVerificationCode: '',
            newEmailVerificationCode: '',
            password: '',
        },
        message: {
            errorMessage: '',
            successMessage: '',
        },
        buttonIsInAction: {
            generateValidationCodeOldEmail: false,
            submitForm: false
        }
    },
    changePasswordForm: {
        fields: {
            verificationCode: '',
            oldPassword: '',
            newPassword: '',
            newPasswordConfirm: '',
        },
        message: {
            errorMessage: '',
            successMessage: '',
        },
        buttonIsInAction: {
            generateValidationCode: false,
            submitForm: false
        }
    },
}

const authReducer = (state = INITIAL_AUTH_STATE, action) => {
    let formName, fieldName, fieldValue, message, buttonName, isInAction, status, profile, mainMenu, translations, settings, mustChangePassLogic,
        editPasswordFormValidationRules, username, email, showLiveWarning;

    switch (action.type) {
        case actionTypes.LOGIN:
        case actionTypes.CHECK_AUTH:
        case actionTypes.LOGOUT:
            state = INITIAL_AUTH_STATE;

            return state;
        case actionTypes.UPDATE_LOGIN_FORM_INPUT:
            ({ formName, fieldName, fieldValue } = action.payload);

            state = {
                ...state,
                [formName]: {
                    ...state[formName],
                    fields: {
                        ...state[formName].fields,
                        [fieldName]: fieldValue
                    }
                }
            };

            return state;
        case actionTypes.UPDATE_EDIT_PASSWORD_FORM_INPUT:
            ({ fieldName, fieldValue } = action.payload);

            state = {
                ...state,
                editPasswordForm: {
                    ...state.editPasswordForm,
                    [fieldName]: fieldValue
                }
            };

            return state;
        case actionTypes.CLEAR_EDIT_PASSWORD_FORM_INPUT:
            state = {
                ...state,
                editPasswordForm: {
                    ...state.editPasswordForm,
                    currentPassword: '',
                    newPassword: '',
                    newPasswordConfirm: '',
                }
            };

            return state;
        case actionTypes.UPDATE_LOGIN_VALIDATION_MESSAGES:
            state = {
                ...state,
                validationMessages: action.payload
            }

            return state;
        case actionTypes.UPDATE_OFFER_FOR_REVISION:
            state = {
                ...state,
                mainMenu: {
                    ...state.mainMenu,
                    offers: {
                        ...state.mainMenu.offers,
                        forRevision: action.payload
                    }
                }
            }

            return state;


        case actionTypes.NEW_UPDATE_FORM_SUCCESS_MESSAGE:
            ({ formName, message } = action.payload);

            state = {
                ...state,
                [formName]: {
                    ...state[formName],
                    message: {
                        ...state[formName].message,
                        successMessage: message
                    }
                }
            };

            return state;

        case actionTypes.NEW_UPDATE_FORM_ERROR_MESSAGE:
            ({ formName, message } = action.payload);

            state = {
                ...state,
                [formName]: {
                    ...state[formName],
                    message: {
                        ...state[formName].message,
                        errorMessage: message
                    }
                }
            };

            return state;

        case actionTypes.NEW_UPDATE_FORM_BUTTON_IN_ACTION:
            ({ formName, buttonName, isInAction } = action.payload);

            state = {
                ...state,
                [formName]: {
                    ...state[formName],
                    buttonIsInAction: {
                        ...state[formName].buttonIsInAction,
                        [buttonName]: isInAction
                    }
                }
            };

            return state;

        case actionTypes.NEW_ON_LOGIN_SUCCESS:
            ({ username, email, mainMenu, translations, settings, showLiveWarning } = action.payload)

            state = INITIAL_AUTH_STATE;
            state = {
                ...state,
                isLoggedIn: true,
                userName: username,
                email: email,
                mainMenu: mainMenu,
                translations: translations,
                settings: settings,
                showLiveWarning: showLiveWarning,
            };

            return state;

        default:
            return state;
    }
}

export default authReducer;
