import _ from 'lodash';
import DOMPurify from 'dompurify';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import { CREATE_FILTER_MODE, EMPTY_VALUE, FORM_CREATE_MODE, FORM_EDIT_MODE, FORM_DATA_TYPES } from './const';
import { ROUTES } from './routes';

export const getRouteObj = (url) => {
    const routeName = _.findKey(ROUTES, { URL: url });
    const routeObj = ROUTES[routeName];

    return routeObj;
}

export const prepareGetParamsObject = (configuration) => {
    let result = {}

    let paginationInfo = configuration.pagination;
    if (paginationInfo) {
        if (paginationInfo.totalRecords) {
            result['total'] = paginationInfo.totalRecords;
        }

        if (paginationInfo.onPage[paginationInfo.onPageSelectedIndex]) {
            result['filter[onPage]'] = paginationInfo.onPage[paginationInfo.onPageSelectedIndex];
        }

        if (paginationInfo.currentPage) {
            result['filter[page]'] = paginationInfo.currentPage;
        }
    }

    if (configuration.filters) {
        Object.keys(configuration.filters).map(
            (columnKey) => {
                let columnActiveFilters = configuration.filters[columnKey].activeFilters;
                Object.keys(columnActiveFilters).map(
                    (index) => {
                        const filter = columnActiveFilters[index];
                        const filterValue = filter.value;
                        const filterComparator = filter.comparator;

                        result[`filter[search][${columnKey}___${index}]`] = `{"value": "${filterValue}", "comparator": "${filterComparator}"}`;

                        return true;
                    }
                )

                return true;
            }
        )
    }

    let header = configuration.gridColumns;
    Object.keys(header).map(key => {
        let isSortable = header[key].order;
        if (isSortable) {
            let direction = header[key].direction;
            if (direction) {
                result[`filter[order][${key}]`] = direction;
            }
        }

        return true;
    });

    return result;
}

export const initFilterForm = (filterableColumnsConfiguration) => {
    let filterColumnsDropdown = {};
    let index = 0;

    filterColumnsDropdown[index] = {
        columnKey: "",
        columnName: "",
        columnDataType: "",
        columnPlaceholder: "...",
        showOnlyEqualityOperator: false,
    }
    index++;

    (Object.keys(filterableColumnsConfiguration)).map(
        columnKey => {
            const columnName = filterableColumnsConfiguration[columnKey].name;
            const columnPlaceholder = filterableColumnsConfiguration[columnKey].placeholder;
            const columnDataType = filterableColumnsConfiguration[columnKey].type;

            filterColumnsDropdown[index] = {
                columnKey: columnKey,
                columnName: columnName,
                columnDataType: columnDataType,
                columnPlaceholder: columnPlaceholder
            }

            if (! _.isUndefined(filterableColumnsConfiguration[columnKey].showOnlyEqualityOperator)) {
                const showOnlyEqualityOperator = filterableColumnsConfiguration[columnKey].showOnlyEqualityOperator;
                filterColumnsDropdown[index].showOnlyEqualityOperator = showOnlyEqualityOperator;
            } else {
                filterColumnsDropdown[index].showOnlyEqualityOperator = false;
            }

            index++;
            return true;
        }
    );

    const filterForm = {
        filterColumnsDropdown: filterColumnsDropdown,
        filterColumnsDropdownSelectedKey: 0,
        filterComparatorDropdown: {
            0: {
                columnKey: "",
                columnName: "",
            }
        },
        filterComparatorDropdownSelectedKey: 0,
        filterValue: "",
        filterDataType: "",
        mode: CREATE_FILTER_MODE,
        isActive: false
    };

    return filterForm;
}

export const validateInput = (value, rules, formFields, fieldName) => {
    let error = false;
    let message = '';

    if (rules) {
        for (let index = 0; index < rules.length; index++) {
            const ruleType = rules[index].ruleType;
            const ruleMessage = rules[index].message;
            const ruleValue = rules[index].ruleValue;

            switch (ruleType) {
                case 'REQUIRED':
                    if (value === null || value === '' || value === EMPTY_VALUE) {
                        error = true;
                    }

                    break;
                case 'EMAIL':
                    if (!(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(value))) {
                        error = true;
                    }

                    break;
                case 'IS_NUMBER':
                    if (isNaN(value)) {
                        error = true;
                    }

                    break;
                case 'MIN_LENGTH':
                    if (value.length < ruleValue) {
                        error = true;
                    }

                    break;
                case 'MAX_LENGTH':
                    if (value.length > ruleValue) {
                        error = true;
                    }

                    break;
                case 'MAX_VALUE':
                    if (value > ruleValue) {
                        error = true;
                    }

                    break;
                case 'MIN_VALUE':
                    if (value < ruleValue) {
                        error = true;
                    }

                    break;
                case 'RULE_MIN_VALUE_OF_OPTION_PROP':
                    const propName = rules[index].propName;
                    const fieldOptions = formFields[fieldName].options;
                    let selectedOption = _.filter(
                        fieldOptions,
                        optionObj => optionObj.value === value
                    );
                    const selectedOptionPropertyValue = selectedOption[0][propName];

                    if (selectedOptionPropertyValue <= ruleValue) {
                        error = true;
                    }

                    break;
                case 'INT_VALUE':
                    if (!isInt(value)) {
                        error = true;
                    }

                    break;
                case 'CONFIRM_PASSWORD':
                    if (value !== formFields['password'].value) {
                        error = true;
                    }

                    break;
                case 'DOUBLE_MAX_PRECISION':
                    if (!isInt(value)) {
                        const numberElements = value.toString().split('.');
                        const precisionElement = numberElements[1];

                        if (precisionElement.length > ruleValue) {
                            error = true;
                        }
                    }

                    break;
                case 'REGEX':
                    let regex = new RegExp(ruleValue);

                    if (value.match(regex) === null) {
                        error = true;
                    }

                    break;
                default:
                    console.log('This type is unknow: ' + ruleType);
            }

            if (error) {
                message = ruleMessage;

                break;
            }
        }
    }

    return [error, message];
}

export const validateDetailedPageAllForms = (allForms) => {
    let detialsFormObject, rowsForEditForms, rowsForCreateForms, editForm, createForm;

    let result = true;
    let message = '';

    let allFormsErrorsForUpdate = {};

    let [
        headerFormIsValid,
        headerFormErrorsForUpdate,
    ] = validateDetailedPageSingleForm(allForms.headerForm.fields, FORM_EDIT_MODE);

    if (!_.isEmpty(headerFormErrorsForUpdate)) {
        allFormsErrorsForUpdate['headerForm'] = headerFormErrorsForUpdate;
    }
    if (!headerFormIsValid) {
        result = false;
    }

    if (!_.isEmpty(allForms.detailedForms)) {
        Object.keys(allForms.detailedForms).forEach(detailFormName => {
            detialsFormObject = allForms.detailedForms[detailFormName];
            rowsForEditForms = detialsFormObject.rowsForEdit;

            if (!_.isEmpty(rowsForEditForms)) {
                Object.keys(rowsForEditForms).forEach(editFormKey => {
                    editForm = rowsForEditForms[editFormKey];

                    let [
                        editFormIsValid,
                        editFormErrorsForUpdate,
                    ] = validateDetailedPageSingleForm(editForm, FORM_EDIT_MODE);

                    if (!_.isEmpty(editFormErrorsForUpdate)) {
                        if (!allFormsErrorsForUpdate.hasOwnProperty(detailFormName)) {
                            allFormsErrorsForUpdate[detailFormName] = {};
                        }
                        if (!allFormsErrorsForUpdate[detailFormName].hasOwnProperty('rowsForEdit')) {
                            allFormsErrorsForUpdate[detailFormName]['rowsForEdit'] = {};
                        }

                        allFormsErrorsForUpdate[detailFormName]['rowsForEdit'][editFormKey] = editFormErrorsForUpdate;
                    }
                    if (!editFormIsValid) {
                        result = false;
                    }
                });
            }

            rowsForCreateForms = detialsFormObject.rowsForCreate;
            if (!_.isEmpty(rowsForCreateForms)) {
                Object.keys(rowsForCreateForms).forEach(createFormKey => {
                    createForm = rowsForCreateForms[createFormKey];
                    let [
                        createFormIsValid,
                        createFormErrorsForUpdate,
                    ] = validateDetailedPageSingleForm(createForm, FORM_CREATE_MODE);

                    if (!_.isEmpty(createFormErrorsForUpdate)) {
                        if (!allFormsErrorsForUpdate.hasOwnProperty(detailFormName)) {
                            allFormsErrorsForUpdate[detailFormName] = {};
                        }
                        if (!allFormsErrorsForUpdate[detailFormName].hasOwnProperty('rowsForCreate')) {
                            allFormsErrorsForUpdate[detailFormName]['rowsForCreate'] = {};
                        }

                        allFormsErrorsForUpdate[detailFormName]['rowsForCreate'][createFormKey] = createFormErrorsForUpdate;
                    }
                    if (!createFormIsValid) {
                        result = false;
                    }
                });
            }
        });
    }

    if (!result) {
        message = 'Form is not valid.';
    }

    return [result, message, allFormsErrorsForUpdate];
}

export const validatePage = (allForms) => {
    let detialsFormObject, rowsForEditForms, rowsForCreateForms, editForm, createForm;

    let result = true;
    let message = '';

    let allFormsErrorsForUpdate = {};

    let [
        headerFormIsValid,
        headerFormErrorsForUpdate,
    ] = validateDetailedPageSingleForm(allForms.fields, allForms.mode);

    if (!_.isEmpty(headerFormErrorsForUpdate)) {
        allFormsErrorsForUpdate['headerForm'] = headerFormErrorsForUpdate;
    }

    if (!headerFormIsValid) {
        result = false;
    }

    //TODO This part is not tested
    if (!_.isEmpty(allForms.relatedData)) {
        Object.keys(allForms.relatedData).forEach(detailFormName => {
            detialsFormObject = allForms.relatedData[detailFormName];
            rowsForEditForms = detialsFormObject.rowsForEdit;
            rowsForCreateForms = detialsFormObject.rowsForCreate;

            if (!_.isEmpty(rowsForEditForms)) {
                _.forEach(rowsForEditForms, (editForm, editFormKey) => {
                    let [editFormIsValid, editFormErrorsForUpdate] = validateDetailedPageSingleForm(editForm, allForms.mode);

                    if (!_.isEmpty(editFormErrorsForUpdate)) {
                        if (!allFormsErrorsForUpdate.hasOwnProperty(detailFormName)) {
                            allFormsErrorsForUpdate[detailFormName] = {};
                        }
                        if (!allFormsErrorsForUpdate[detailFormName].hasOwnProperty('rowsForEdit')) {
                            allFormsErrorsForUpdate[detailFormName]['rowsForEdit'] = {};
                        }

                        allFormsErrorsForUpdate[detailFormName]['rowsForEdit'][editFormKey] = editFormErrorsForUpdate;
                    }
                    if (!editFormIsValid) {
                        result = false;
                    }
                });
            }

            if (!_.isEmpty(rowsForCreateForms)) {
                _.forEach(rowsForCreateForms, (createForm, createFormKey) => {
                    let [createFormIsValid, createFormErrorsForUpdate] = validateDetailedPageSingleForm(createForm, FORM_CREATE_MODE);

                    if (!_.isEmpty(createFormErrorsForUpdate)) {
                        if (!allFormsErrorsForUpdate.hasOwnProperty(detailFormName)) {
                            allFormsErrorsForUpdate[detailFormName] = {};
                        }
                        if (!allFormsErrorsForUpdate[detailFormName].hasOwnProperty('rowsForCreate')) {
                            allFormsErrorsForUpdate[detailFormName]['rowsForCreate'] = {};
                        }

                        allFormsErrorsForUpdate[detailFormName]['rowsForCreate'][createFormKey] = createFormErrorsForUpdate;
                    }
                    if (!createFormIsValid) {
                        result = false;
                    }
                });
            }
        });
    }

    if (!result) {
        message = 'Form is not valid.';
    }

    return [result, message, allFormsErrorsForUpdate];
}

export const validateDetailedPageSingleForm = (formFields, mode) => {
    let fieldConfiguration, fieldValue, fieldValidationRules, fieldIsError;

    let formIsValid = true;

    const headerFormErrorsForUpdate = {};
    let numberOfInvalidFields = 0;

    if (formFields) {
        Object.keys(formFields).forEach(fieldName => {
            fieldConfiguration = formFields[fieldName];
            fieldValue = fieldConfiguration.value;
            fieldValidationRules = fieldConfiguration.validationRules;
            fieldIsError = fieldConfiguration.error;

            if (fieldValidationRules !== undefined && fieldConfiguration.readOnly[mode] === false) { //Fields that dont have validationRules or are disabled should not be validated
                if (fieldIsError) {
                    numberOfInvalidFields++;
                } else {
                    const [fieldError, fieldErrorMessage] = validateInput(fieldValue, fieldValidationRules, formFields, fieldName);

                    if (fieldError) {
                        headerFormErrorsForUpdate[fieldName] = { error: fieldError, errorMessage: fieldErrorMessage };
                        numberOfInvalidFields++;
                    }
                }
            }
        });
    }

    if (numberOfInvalidFields > 0) {
        formIsValid = false;
    }

    return [formIsValid, headerFormErrorsForUpdate];
}

export const concatEvent = ({ target, fields }, formFields, action) => {
    let targetNewValue = '';

    fields.forEach(fieldName => {
        let fieldValue = formFields[fieldName].value;

        if (targetNewValue !== '' && fieldValue !== '') {
            targetNewValue += ' ';
        }

        if (fieldValue !== undefined) {
            targetNewValue += fieldValue;
        }
    });

    action(target, targetNewValue);
}

//Tipically a dropdown options single object look like {key: '', value: '', text: ''}
//In this case we have additional info {[propName]: propValue}, which we use for setting value for another field
export const setOtherFiledValueOnDropdownSelectEvent = ({ target, propName }, formFields, fieldValue, fieldName, action, validateAction) => {
    const targetsValidationRules = formFields[target].validationRules;
    const targetsEvents = formFields[target].event;

    const allCurrentFieldOptions = formFields[fieldName].options;
    let selectedOptionObject = _.filter(allCurrentFieldOptions, optionObj => optionObj.value === fieldValue);

    if (selectedOptionObject.length === 1) { //Just in case check it; IF BE data is OK, always should be just one option
        selectedOptionObject = selectedOptionObject[0];
        const propValue = selectedOptionObject[propName];

        action(target, propValue, targetsEvents);
        validateAction(target, propValue, targetsValidationRules);
    }
}

export const disableEvent = ({ valuesToCompare }, fieldValue, fieldName, action) => {
    const disabled = valuesToCompare.includes(fieldValue);

    action(fieldName, disabled);
}

export const ajaxUrlEvent = ({ url, paramsNeeded }, formFields, action, rowKey = null, mode = null, fieldName = null) => {
    let postData = {};

    paramsNeeded.forEach(paramName => postData[paramName] = formFields[paramName].value);

    postData.fieldName = fieldName;

    action(url, postData, rowKey, mode);
}

export const formulaEvent = ({ target, fields, initialValue, precision }, formFields, action) => {
    let targetNewValue;

    switch (initialValue.type) {
        case "NUMERIC_VALUE":
            targetNewValue = parseFloat(initialValue["value"]);

            break;
        case "GET_VALUE_BY_FIELD_NAME":
            const fieldName = initialValue.name;
            targetNewValue = parseFloat(formFields[fieldName].value);

            break;
        default:
            break;
    }

    try {
        fields.forEach(field => {
            let fieldValue = 0;

            switch (field.type) {
                case "NUMERIC_VALUE":
                    fieldValue = parseFloat(field.value);

                    break;
                case "GET_VALUE_BY_FIELD_NAME":
                    const fieldName = field.name;
                    fieldValue = parseFloat(formFields[fieldName].value);

                    break;
                default:
                    break;
            }

            if (isNaN(fieldValue) || fieldValue < 0) {
                throw { message: 'Invalid value', fieldName: field.name, fieldValue: fieldValue };
            }

            switch (field.method) {
                case "ADD":
                    targetNewValue += fieldValue;

                    break;
                case "SUBTRACT":
                    targetNewValue -= fieldValue;

                    break;
                case "MULTIPLY":
                    targetNewValue *= fieldValue;

                    break;
                case "DIVIDE":
                    targetNewValue /= fieldValue;

                    break;
                default:
                    break;
            }
        });

        targetNewValue = parseFloat(targetNewValue.toFixed(precision));
    } catch (e) {
        targetNewValue = '';
    }

    action(target, targetNewValue);
}

export const isInt = (n) => {
    return n % 1 === 0;
}

export const getValue = (obj, name) => {
    if (obj === undefined || name === undefined) {
        return ' String Error ';
    }

    return obj[name] === undefined ? name : obj[name];
}

export const preparePostParamsNEW_NEW = (formData, fields, nodeName = '') => {
    _.forEach(fields, (field, postParamName) => {
        let { value, dataType, values, dimensionsList, checkedSliders } = field;

        let fileBlob = EMPTY_VALUE;
        let fileName = EMPTY_VALUE;
        let postParamValue = EMPTY_VALUE;

        if (dataType === FORM_DATA_TYPES.INPUT_FIELD_RICH_TEXT_EDITOR) {
            const html = draftToHtml(convertToRaw(value.getCurrentContent()));
            postParamValue = DOMPurify.sanitize(html);

            console.log(postParamValue);
        } else if (dataType === FORM_DATA_TYPES.INPUT_FIELD_FILE || dataType === FORM_DATA_TYPES.INPUT_FIELD_FILE_NO_PREVIEW) { //ОК
            if (value instanceof Blob) {
                fileBlob = value;
                fileName = value.name;
                postParamValue = value.name; //add the name of the file also
            }
        } else if (dataType === FORM_DATA_TYPES.DROPDOWN_MULTI) { //ОК 
            if (!_.isEmpty(value)) {
                postParamValue = JSON.stringify(value);
            }
        } else if (dataType === FORM_DATA_TYPES.BUTTON_OPTIONS) { //ОК
            postParamValue = JSON.stringify(values);
        } else if (dataType === FORM_DATA_TYPES.DIMENSIONS) {
            let dimensionValues = {};
            _.forEach(dimensionsList, (dimensionObj, dimensionName) => {
                dimensionValues[dimensionName] = dimensionObj.value;
            })

            postParamValue = JSON.stringify(dimensionValues);
        } else if (dataType === FORM_DATA_TYPES.SLIDERS) { //ОК
            postParamValue = JSON.stringify(checkedSliders);
        } else if (dataType === FORM_DATA_TYPES.INPUT_FIELD_PASSWORD) { //ОК
            if (value !== false) { //It is false in Edit mode, when we do not reset it
                postParamValue = value;
            }
        } else if (dataType === FORM_DATA_TYPES.CHECKBOX) {
            if (value === 0 || value === false) {
                postParamValue = 0;
            } else {
                postParamValue = 1;
            }
        } else {
            postParamValue = value;
        }

        postParamValue = _.isUndefined(postParamValue) ? '' : postParamValue;

        //Append File
        if (fileBlob !== EMPTY_VALUE && fileName !== EMPTY_VALUE) {
            if (nodeName !== '') {
                formData.append(`${nodeName}[${postParamName}]`, fileBlob, fileName);
            } else {
                formData.append(postParamName, fileBlob, fileName);
            }
        }

        //Append Data
        if (nodeName !== '') {
            formData.append(`${nodeName}[${postParamName}]`, postParamValue);
        } else {
            formData.append(postParamName, postParamValue);
        }
    });
}

export const prepareDetailedTabPostParamsNEW_NEW = (data, nodeName, formData) => {
    let { rowsForCreate, rowsForEdit, rowsForDelete } = data;

    _.forEach(rowsForCreate, (fields, rowKey) => {
        let subNodeName = `${nodeName}[rowsForCreate][${rowKey}]`;
        preparePostParamsNEW_NEW(formData, fields, subNodeName);
    });

    _.forEach(rowsForEdit, (fields, rowKey) => {
        let subNodeName = `${nodeName}[rowsForEdit][${rowKey}]`;
        preparePostParamsNEW_NEW(formData, fields, subNodeName);
    });

    if (rowsForDelete) {
        rowsForDelete = JSON.stringify(rowsForDelete);

        formData.append(`${nodeName}[rowsForDelete]`, rowsForDelete);
    }
}

export const prepareRelatedDataPostParamsNEW_NEW = (relatedData, nodeName, formData) => {
    _.forEach(relatedData, (data, name) => {
        let subNodeName = `${nodeName}[${name}]`;

        prepareDetailedTabPostParamsNEW_NEW(data, subNodeName, formData);
    });
}
