import React from 'react';
import { Label, Segment, Container, Grid, Header, List, Button, Icon, Popup } from 'semantic-ui-react';

const Footer = ({ version }) => {
    const renderLabel = () => {
        let result = null;
        if (version) {
            result = (
                <Label circular color='black'>
                    {version ? 'v. ' + version : ''}
                </Label>
            );
        }

        return result;
    }

    return (
        <Segment raised>
            <Grid columns='equal'>
                <Grid.Column>
                    <List.Item >
                        <span style={{marginRight: '0.3em', fontSize: '11px'}}>Powered by</span>
                        <b>
                            <a
                                href="https://worksoftpro.com/en/"
                                style={{ color: "black" }}
                                target="_blank"
                            >
                                <img
                                    src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTX1DcEmXS-m6Qin_x6YB-v6BCA35cKUjjymA&s'
                                    style={{ width: '20px', height: '20px', marginRight: '-0.3em' }}>
                                </img> WorkSoft Pro
                            </a>
                        </b>
                    </List.Item>
                </Grid.Column>
                <Grid.Column textAlign='right'>
                    <Popup
                        trigger={
                            <Button
                                color='youtube'
                                size='mini'
                                href="https://www.youtube.com/playlist?list=PLHUna4GRDH_LIFHqvAjylpdq9YEpOl6Lh"
                                target="_blank"
                            >
                                <Icon name='youtube' /> YT (всички)
                            </Button>
                        }
                        content="Линк към видео помощ"
                    />
                    
                    <Popup
                        trigger={
                            <Button
                                color='youtube'
                                size='mini'
                                href="https://www.youtube.com/watch?v=QOOAkyJQMxg&list=PLHUna4GRDH_LIFHqvAjylpdq9YEpOl6Lh&index=4"
                                target="_blank"
                            >
                                <Icon name='youtube' /> YT (мениджъри на клубове)
                            </Button>
                        }
                        content="Линк към видео помощ за Мениджъри на Клубове"
                    />

                    <Popup
                        trigger={
                            <Button
                                color='youtube'
                                size='mini'
                                href="https://www.youtube.com/watch?v=O0yI2STCWcA&list=PLHUna4GRDH_LIFHqvAjylpdq9YEpOl6Lh&index=5"
                                target="_blank"
                            >
                                <Icon name='youtube' /> YT (състезатели)
                            </Button>
                        }
                        content="Линк към видео помощ за Състезатели"
                    />

                    {renderLabel()}
                </Grid.Column>
            </Grid>
        </Segment>
    );
}

export default Footer;
