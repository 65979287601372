import actionTypes from "./actionTypes";
import _ from "lodash";
import ioms from "../../apis/ioms";
import history from '../../history';
import { ROUTES } from '../../helpers/routes';
import { MESSAGE_ERROR, MESSAGE_SUCCESS, MESSAGE_WARNING } from '../../helpers/const';
import { addMessage } from '../messageActions';
import { refresh } from '../notificationBar/actions';
import { downloadFileByPath } from '../gridPage/existingRecordActions';
import fileSaver from '../../fileSaver';
import { close, toggleSpinner as toggleSpinnerModalTree, updateError } from '../revisionModalTree/actions';

export const downloadPdf = (url, fileName) => async () => {
    const { data } = await ioms.get(url, { withCredentials: true, responseType: 'blob' });

    await fileSaver(data, fileName);
};

export const deleteEntity = (deleteUrl, nextRoute) => async (dispatch, getState) => {
    try {
        const response = await ioms.get(deleteUrl, { withCredentials: true });
        await dispatch(refresh());

        if (response.data.success) {
            history.push(nextRoute);
        } else {
            dispatch(addMessage(MESSAGE_ERROR, { content: response.data.result.message, visible: true }));
        }
    } catch (error) {
        await dispatch(addMessage(MESSAGE_ERROR, { content: error.message, visible: true }));
    }
};

export const submitAdditionalAction = (actionUrl, apiFetchUrl, id) => async (dispatch, getState) => {
    try {
        const response = await ioms.get(actionUrl, { withCredentials: true });

        if (response.data.success) {
            await dispatch(refresh());

            dispatch(addMessage(MESSAGE_SUCCESS, { content: response.data.result.message, visible: true }));

            dispatch(fetchHeaderFormReadMode(apiFetchUrl, id));
        } else {
            dispatch(addMessage(MESSAGE_ERROR, { content: response.data.result.message, visible: true }));
        }
    } catch (error) {
        await dispatch(addMessage(MESSAGE_ERROR, { content: error.message, visible: true }));
    }
};

export const fetchHeaderFormReadMode = (fetchUrl, id) => async (dispatch) => {
    try {
        fetchUrl = fetchUrl.replace(/:id/, id);
        const response = await ioms.get(fetchUrl, { withCredentials: true });

        if (response.data.success) {
            dispatch({
                type: actionTypes.FETCH_DETAIL_READ_DATA,
                payload: response.data.result.data
            });
        } else {
            const { sessionExpired, closedUserLog, permission, shouldChangePassword, shouldAcceptTermsAndConditions } = response.data;
            
            let shouldAddErrorMessage = true;
            let route;
            if (sessionExpired) {
                route = ROUTES.LOGIN_ROUTE.URL;
            } else if (closedUserLog) {
                route = ROUTES.LOGIN_ROUTE.URL;
            } else if (permission) {
                route = ROUTES.HOME_SCREEN_ROUTE.URL;
            } else if (shouldChangePassword) {
                route = ROUTES.EDIT_PASSWORD_ROUTE.URL;
            } else if (shouldAcceptTermsAndConditions) {
                route = ROUTES.TERMS_AND_CONDITIONS_DECISION_SCREEN.URL;
                shouldAddErrorMessage = false;
            }
            
            if (shouldAddErrorMessage) {
                dispatch(addMessage(MESSAGE_ERROR, { content: response.data.messages[0].text, visible: true }));
            }

            history.push(route);
        }
    } catch (error) {
        await dispatch(addMessage(MESSAGE_ERROR, { content: error.message, visible: true }));
    }
};

export const toggleSpinner = () => {
    return {
        type: actionTypes.TOGGLE_SPINNER_DETAIL_READ_DATA,
    }
}

export const clearForm = () => {
    return {
        type: actionTypes.CLEAR_DETAIL_READ_DATA,
    };
}

export const setCheckboxTreeExpanded = (detailName, expanded) => {
    return {
        type: actionTypes.SET_TREE_EXPANDED_READ,
        payload: { detailName, expanded }
    }
}

export const generateAndDownload = (generateFileUrl) => async (dispatch, getState) => {
    try {
        const response = await ioms.get(generateFileUrl, { withCredentials: true });

        const { serverFilePath, fileName, downloadUrl, success, message } = response.data.result;

        if (success) {
            await dispatch(downloadFileByPath(downloadUrl, serverFilePath, fileName));

            dispatch(addMessage(MESSAGE_SUCCESS, { content: message, visible: true }));
        } else {
            dispatch(addMessage(MESSAGE_ERROR, { content: message, visible: true }));
        }
    } catch (error) {
        dispatch(addMessage(MESSAGE_ERROR, { content: error.message, visible: true }));
    }
}

export const confirmRevisionTree = (postData, confirmRevisionTreeUrl, apiFetchUrl, id) => async (dispatch, getState) => {
    try {
        await dispatch(toggleSpinnerModalTree());

        const response = await ioms.post(confirmRevisionTreeUrl, postData, { withCredentials: true });

        const { success, message, warningMessages } = response.data.result;

        if (success) {
            await dispatch(close());

            await dispatch(addMessage(MESSAGE_SUCCESS, { content: message, visible: true }));

            await dispatch(refresh());

            if (!_.isUndefined(warningMessages) && warningMessages) {
                _.forEach(warningMessages, async warningMessage => await dispatch(addMessage(MESSAGE_WARNING, { content: warningMessage, visible: true })))
            }

            dispatch(fetchHeaderFormReadMode(apiFetchUrl, id));
        } else {
            await dispatch(toggleSpinnerModalTree());

            dispatch(updateError([message]));
        }
    } catch (error) {
        await dispatch(toggleSpinnerModalTree());
    }
}
