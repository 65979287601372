import React from 'react';
import { Icon, Button } from 'semantic-ui-react';
import { FormButtonProp } from '../../../../enum/apiResultProp/FormButtonProp';

export default ({ buttonProps, onButtonClick, size, className }) => {
    const buttonName = buttonProps[FormButtonProp.ButtonName];
    const text = buttonProps[FormButtonProp.Text];
    const iconName = buttonProps[FormButtonProp.IconName];
    const showSpinner = buttonProps[FormButtonProp.ShowSpinner];
    const disabled = buttonProps[FormButtonProp.Disabled];

    return (
        <Button 
            disabled={disabled}
            color='blue'
            size='tiny'
            loading={showSpinner}
            onClick={onButtonClick}
            style={{backgroundColor: 'white', color: "#3C8AD9", borderColor: '#3C8AD9', border: '1px solid'}}
        >
            <Icon name={iconName} />
            {text}
        </Button>
    );
}