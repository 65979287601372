import _, { isNull, isUndefined, size } from "lodash";
import ioms from "../../apis/ioms";
import newScreenActionTypes from "./newScreenActionTypes";
import { addMessage } from '../messageActions';
import { MESSAGE_ERROR, MESSAGE_SUCCESS } from '../../helpers/const';
import { ScreenComponentProp } from "../../enum/apiResultProp/ScreenComponentProp";
import { ScreenComponentType } from "../../enum/ScreenComponentType";
import { ScreenProp } from "../../enum/apiResultProp/ScreenProp";
import { DataGridProp } from "../../enum/dataGrid/DataGridProp";
import { DataGridSorting } from "../../enum/dataGrid/DataGridSorting";
import { DataGridPaginationProp } from "../../enum/dataGrid/DataGridPaginationProp";
import { FilterProp } from "../../enum/dataGrid/filter/FilterProp";
import { ComparatorFieldProp } from "../../enum/dataGrid/filter/ComparatorFieldProp";
import { ValueFieldProp } from "../../enum/dataGrid/filter/ValueFieldProp";
import { DataType } from "../../enum/DataType";
import { saveGridPageParams } from '../../actions/gridFilterParams/actions';

const useLoaderOnBackEndRequest = false;

export const dataGridStartSpinner = ({ dataGridComponentName }) => {
    return {
        type: newScreenActionTypes.NEW_SCREEN_DATA_GRID_START_SPINNER,
        payload: { dataGridComponentName }
    }
}

export const dataGridStopSpinner = ({ dataGridComponentName }) => {
    return {
        type: newScreenActionTypes.NEW_SCREEN_DATA_GRID_STOP_SPINNER,
        payload: { dataGridComponentName }
    }
}

export const selectAll = (params) => {
    return {
        type: newScreenActionTypes.NEW_SCREEN_DATA_GRID_SELECT_ALL_CLICK,
        payload: params
    }
}

export const selectSingle = (params) => {
    return {
        type: newScreenActionTypes.NEW_SCREEN_DATA_GRID_SELECT_SINGLE_CLICK,
        payload: params
    }
}

export const clearAllNotAppliedEmptyFilters = (params) => {
    return {
        type: newScreenActionTypes.NEW_SCREEN_DATA_GRID_CLEAR_ALL_NOT_APPLIED_EMPTY_FILTERS,
        payload: params
    }
}

export const onFilterColumnChange = (params) => {
    return {
        type: newScreenActionTypes.NEW_SCREEN_DATA_GRID_ON_FILTER_COLUMN_CHANGE,
        payload: params
    }
}

export const onFilterComparatorChange = (params) => {
    return {
        type: newScreenActionTypes.NEW_SCREEN_DATA_GRID_ON_FILTER_COMPARATOR_CHANGE,
        payload: params
    }
}

export const onFilterValueChange = (params) => {
    return {
        type: newScreenActionTypes.NEW_SCREEN_DATA_GRID_ON_FILTER_VALUE_CHANGE,
        payload: params
    }
}
export const createNewFilter = (params) => {
    return {
        type: newScreenActionTypes.NEW_SCREEN_DATA_GRID_CREATE_FILTER,
        payload: params
    }
}

export const onDeleteNotAppliedFilterRow = (params) => {
    return {
        type: newScreenActionTypes.NEW_SCREEN_DATA_GRID_DELETE_NOT_APPLIED_FILTER,
        payload: params
    }
}

export const onGridActivePageChangeMutateComponentWithPostData = ({ backEndUrl, currentRouteName, preserveFilters, collectDataFromComponentName, newActivePage }) => async (dispatch, getState) => {
    const formData = new FormData();

    const dataGridProps = getState().screenData[ScreenProp.Components][collectDataFromComponentName];
    buildDataGridPost({ formData, dataGridProps, newActivePage });
    mutateGrid({ backEndUrl, formData, dispatch, collectDataFromComponentName, currentRouteName, preserveFilters });
}

export const onGridRecordsPerPageChangeMutateComponentWithPostData = ({ backEndUrl, currentRouteName, preserveFilters, collectDataFromComponentName, recordsPerPage }) => async (dispatch, getState) => {
    const formData = new FormData();

    const dataGridProps = getState().screenData[ScreenProp.Components][collectDataFromComponentName];
    buildDataGridPost({ formData, dataGridProps, newRecordsPerPage: recordsPerPage, newActivePage: 1 });
    mutateGrid({ backEndUrl, formData, dispatch, collectDataFromComponentName, currentRouteName, preserveFilters });
}

export const onGridSortChangeMutateComponentWithPostData = ({ backEndUrl, currentRouteName, preserveFilters, collectDataFromComponentName, columnName }) => async (dispatch, getState) => {
    const formData = new FormData();

    const dataGridProps = getState().screenData[ScreenProp.Components][collectDataFromComponentName];
    buildDataGridPost({ formData, dataGridProps, newSortColumnName: columnName, newActivePage: 1 });
    mutateGrid({ backEndUrl, formData, dispatch, collectDataFromComponentName, currentRouteName, preserveFilters });
}

export const onApplyAllFiltersMutateComponentWithPostData = ({ backEndUrl, currentRouteName, preserveFilters, collectDataFromComponentName }) => async (dispatch, getState) => {
    const formData = new FormData();

    const dataGridProps = getState().screenData[ScreenProp.Components][collectDataFromComponentName];
    buildDataGridPost({ formData, dataGridProps, newActivePage: 1 });
    mutateGrid({ backEndUrl, formData, dispatch, collectDataFromComponentName, currentRouteName, preserveFilters });
}

export const onDeleteAllFiltersMutateComponentWithPostData = ({ backEndUrl, currentRouteName, preserveFilters, collectDataFromComponentName, forColumn }) => async (dispatch, getState) => {
    const formData = new FormData();

    const dataGridProps = getState().screenData[ScreenProp.Components][collectDataFromComponentName];
    buildDataGridPost({ formData, dataGridProps, deleteAllFilters: true, deleteAllFiltersForColumnName: forColumn, newActivePage: 1 });
    mutateGrid({ backEndUrl, formData, dispatch, collectDataFromComponentName, deleteAllFilters: true, deleteAllFiltersForColumnName: forColumn, currentRouteName, preserveFilters });
}

export const onDeleteAppliedFilterRowMutateComponentWithPostData = ({ backEndUrl, currentRouteName, preserveFilters, collectDataFromComponentName, columnName, id }) => async (dispatch, getState) => {
    const formData = new FormData();

    const dataGridProps = getState().screenData[ScreenProp.Components][collectDataFromComponentName];
    buildDataGridPost({ formData, dataGridProps, filterRowDeletedColumnName: columnName, filterRowDeletedId: id });
    mutateGrid({ backEndUrl, formData, dispatch, collectDataFromComponentName, filterRowDeletedColumnName: columnName, filterRowDeletedId: id, currentRouteName, preserveFilters });
}

export const onDeleteRecordMutateComponentWithPostData = ({ backEndUrl, collectDataFromComponentName }) => async (dispatch, getState) => {
    const formData = new FormData();

    const dataGridProps = getState().screenData[ScreenProp.Components][collectDataFromComponentName];
    buildDataGridPost({ formData, dataGridProps });
    mutateGrid({ backEndUrl, formData, dispatch, collectDataFromComponentName, showMessages: true });
}

const mutateGrid = async ({
    backEndUrl,
    formData,
    dispatch,
    collectDataFromComponentName,
    currentRouteName = '',
    preserveFilters = false,
    showMessages = false,
    deleteAllFilters = false,
    deleteAllFiltersForColumnName = null,
    filterRowDeletedColumnName = null,
    filterRowDeletedId = null,
}) => {
    if (useLoaderOnBackEndRequest) {
        dispatch(dataGridStartSpinner({ dataGridComponentName: collectDataFromComponentName }));
    }

    try {
        const response = await ioms.post(backEndUrl, formData, { withCredentials: true });
        if (response.data.success) {
            await dispatch({
                type: newScreenActionTypes.NEW_SCREEN_ACTION_MUTATE_COMPONENT,
                payload: { ...response.data.result, deleteAllFilters, deleteAllFiltersForColumnName, filterRowDeletedColumnName, filterRowDeletedId }
            })

            if (showMessages) {
                const messages = response.data.messages;
                if (messages) {
                    _.forEach(messages, message => {
                        dispatch(addMessage(MESSAGE_SUCCESS, { content: message.text, visible: true }));
                    })
                }
            }

            if (preserveFilters) {
                dispatch(saveGridPageParams(currentRouteName, formData ));
            }
        } else {
            const messages = response.data.messages;
            if (messages) {
                _.forEach(messages, message => {
                    dispatch(addMessage(MESSAGE_ERROR, { content: message.text, visible: true }));
                })
            }
        }
    } catch (error) {
        await dispatch(addMessage(MESSAGE_ERROR, { content: error.message, visible: true }));
    }

    if (useLoaderOnBackEndRequest) {
        dispatch(dataGridStopSpinner({ dataGridComponentName: collectDataFromComponentName }));
    }
}

export const buildDataGridPost = ({
    formData,
    dataGridProps,
    newRecordsPerPage = null,
    newActivePage = null,
    newSortColumnName = null,
    filterRowDeletedColumnName = null,
    filterRowDeletedId = null,
    deleteAllFilters = false,
    deleteAllFiltersForColumnName = null,
}) => {
    const formDataDebugAssoc = {};

    const componentType = dataGridProps[ScreenComponentProp.ScreenComponentType];
    if (componentType === ScreenComponentType.DataGrid) {
        let sorting = dataGridProps[DataGridProp.Sorting];
        // We do not mutate state here; The mutation will be done on success from server response mutate data
        if (sorting && newSortColumnName) {
            _.forEach(sorting, (currentSortDirection, columnName) => {
                let newSortDirection;
                if (columnName !== newSortColumnName) {
                    if (currentSortDirection === DataGridSorting.NoSort) {
                        newSortDirection = DataGridSorting.NoSort;
                    } else {
                        newSortDirection = DataGridSorting.Neutral;
                    }
                } else {
                    if (currentSortDirection === DataGridSorting.NoSort) {
                        console.log('You cannot sort this column!!!');
                        newSortDirection = DataGridSorting.NoSort;
                    } else if (currentSortDirection === DataGridSorting.Neutral) {
                        newSortDirection = DataGridSorting.Asc;
                    } else if (currentSortDirection === DataGridSorting.Asc) {
                        newSortDirection = DataGridSorting.Desc;
                    } else if (currentSortDirection === DataGridSorting.Desc) {
                        newSortDirection = DataGridSorting.Neutral;
                    } else {
                        newSortDirection = DataGridSorting.Neutral;
                    }
                }

                sorting = {
                    ...sorting,
                    [columnName]: newSortDirection
                };
            })
        }

        let pagination = dataGridProps[DataGridProp.Pagination];
        if (pagination) {
            if (newRecordsPerPage) {
                pagination = {
                    ...pagination,
                    [DataGridPaginationProp.RecordsPerPage]: newRecordsPerPage
                };
            }
            if (newActivePage) {
                pagination = {
                    ...pagination,
                    [DataGridPaginationProp.ActivePage]: newActivePage
                };
            }
        }

        // Add filters to FormData
        // Here we send the filters we want to apply
        // If All filters deleted -> we are going send only the filters we want to apply and ON success -> we are going to delete them from state
        const filtersByColumn = dataGridProps[DataGridProp.Filters];
        _.forEach(filtersByColumn, (filters, columnName) => {
            _.forEach(filters, (filterProps) => {
                const valueFieldValue = filterProps[FilterProp.ValueField][ValueFieldProp.Value];
                const dataType = filterProps[FilterProp.ValueField][ValueFieldProp.DataType];
                if (!isNull(valueFieldValue) && valueFieldValue !== '' && !isUndefined(valueFieldValue)) {
                    const comparatorFieldValue = filterProps[FilterProp.ComparatorField][ComparatorFieldProp.Value];
                    const id = filterProps[FilterProp.Id];

                    let filterIsForDelete = false;
                    if (deleteAllFilters) {
                        if (isNull(deleteAllFiltersForColumnName)) {
                            filterIsForDelete = true;
                        } else if (deleteAllFiltersForColumnName === columnName) {
                            filterIsForDelete = true;
                        }
                    }

                    // const rootNameFilter = `[${componentName}]filter`;
                    const rootNameFilter = `filter`;

                    if (!filterIsForDelete && filterRowDeletedColumnName !== columnName && filterRowDeletedId !== id) {
                        formData.append(rootNameFilter + `[${columnName + '_' + id}][columnName]`, columnName);
                        formDataDebugAssoc[rootNameFilter + `[${columnName + '_' + id}][columnName]`] = columnName;

                        formData.append(rootNameFilter + `[${columnName + '_' + id}][id]`, id);
                        formDataDebugAssoc[rootNameFilter + `[${columnName + '_' + id}][id]`] = id;

                        formData.append(rootNameFilter + `[${columnName + '_' + id}][comparatorName]`, comparatorFieldValue);
                        formDataDebugAssoc[rootNameFilter + `[${columnName + '_' + id}][comparatorName]`] = comparatorFieldValue;

                        if (dataType === DataType.Array) {
                            formData.append(rootNameFilter + `[${columnName + '_' + id}][value]`, JSON.stringify(valueFieldValue));
                            formDataDebugAssoc[rootNameFilter + `[${columnName + '_' + id}][value]`] = JSON.stringify(valueFieldValue);
                        } else {
                            formData.append(rootNameFilter + `[${columnName + '_' + id}][value]`, valueFieldValue);
                            formDataDebugAssoc[rootNameFilter + `[${columnName + '_' + id}][value]`] = valueFieldValue;
                        }
                    }
                }
            })
        });

        // Add sorting to FormData
        // const rootNameSort = `[${componentName}]sort`;
        const rootNameSort = `sort`;
        if (sorting) {
            _.forEach(sorting, (sortDirection, columnName) => {
                const formDataKey = rootNameSort + `[${columnName}]`;
                formData.append(formDataKey, sortDirection);
                formDataDebugAssoc[formDataKey] = sortDirection;
            });
        } else {
            formData.append(rootNameSort, {});
            formDataDebugAssoc[rootNameSort] = {};
        }

        // const rootNamePagination = `[${componentName}]pagination`;
        const rootNamePagination = `pagination`;
        // Add pagination to FormData
        if (pagination) {
            formData.append(rootNamePagination + `[activePage]`, pagination[DataGridPaginationProp.ActivePage]);
            formDataDebugAssoc[rootNamePagination + `[activePage]`] = pagination[DataGridPaginationProp.ActivePage];

            formData.append(rootNamePagination + `[recordsPerPage]`, pagination[DataGridPaginationProp.RecordsPerPage]);
            formDataDebugAssoc[rootNamePagination + `[recordsPerPage]`] = pagination[DataGridPaginationProp.RecordsPerPage];
        } else {
            formData.append(rootNamePagination, {});
            formDataDebugAssoc[rootNamePagination] = {};
        }

        const selectedRowIds = dataGridProps[DataGridProp.SelectedRowIds];
        const selectedRowIdsResult = JSON.stringify(selectedRowIds);
        formData.append('selectedRowIds', selectedRowIdsResult);
        formDataDebugAssoc['selectedRowIds'] = selectedRowIdsResult;
    }
}
