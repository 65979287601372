import actionTypes from "./actionTypes";
import ioms from "../../apis/ioms";
import { ROUTES } from "../../helpers/routes";

export const fetch = (routeName) => async (dispatch) => {
    try {
        await dispatch(startSpinner());

        const response = await ioms.post(ROUTES.GET_PAGE_DOCUMENTATION_ROUTE.API_FETCH_URL, { routeName }, { withCredentials: true });
        const { success, documentation } = response.data.result;

        if (success) {
            await dispatch({
                type: actionTypes.APP_DOCUMENTATION_FETCH_DATA,
                payload: documentation
            });
            await dispatch(stopSpinner());
        } else {
            await dispatch(stopSpinner());
        }
    } catch (error) {
        await dispatch(stopSpinner());
    }
}

export const openModal = (routeName) => async (dispatch) => {
    dispatch({
        type: actionTypes.APP_DOCUMENTATION_OPEN_MODAL,
    });
    dispatch(fetch(routeName));
};

export const closeModal = () => {
    return {
        type: actionTypes.APP_DOCUMENTATION_CLOSE_MODAL,
    };
};

export const startSpinner = () => {
    return {
        type: actionTypes.APP_DOCUMENTATION_SPINNER_START,
    };
};

export const stopSpinner = (data) => {
    return {
        type: actionTypes.APP_DOCUMENTATION_SPINNER_STOP,
    };
};
