import actionTypes from "./actionTypes";
import { addMessage } from '../messageActions';
import { MESSAGE_SUCCESS, MESSAGE_ERROR, FORM_DATA_TYPES } from '../../helpers/const';
import { ROUTES } from '../../helpers/routes';
import ioms from "../../apis/ioms";
import history from "../../history";
import { downloadFileByPath } from '../gridPage/existingRecordActions';
import _ from "lodash";

export const fetchPriceListDataAndOpen = (fetchUrl) => async (dispatch) => {
    try {
        const response = await ioms.get(fetchUrl, { withCredentials: true });

        const { success, result, messages, sessionExpired, closedUserLog, permission, shouldChangePassword, shouldAcceptTermsAndConditions } = response.data;

        if (success) {
            dispatch({
                type: actionTypes.PRICE_LIST_FETCH_DATA,
                payload: result
            });
        } else {
            let route;
            let shouldAddErrorMessage = true;
            if (sessionExpired) {
                route = ROUTES.LOGIN_ROUTE.URL;
            } else if (closedUserLog) {
                route = ROUTES.LOGIN_ROUTE.URL;
            } else if (permission) {
                route = ROUTES.HOME_SCREEN_ROUTE.URL;
            } else if (shouldChangePassword) {
                route = ROUTES.EDIT_PASSWORD_ROUTE.URL;
            } else if (shouldAcceptTermsAndConditions) {
                route = ROUTES.TERMS_AND_CONDITIONS_DECISION_SCREEN.URL;
                shouldAddErrorMessage = false;
            }
            
            if (shouldAddErrorMessage) {
                dispatch(addMessage(MESSAGE_ERROR, { content: messages[0].text, visible: true }));
            }

            history.push(route);
        }
    } catch (error) {
        await dispatch(addMessage(MESSAGE_ERROR, { content: error.message, visible: true }));
    }
}

export const toggleSpinner = () => {
    return {
        type: actionTypes.PRICE_LIST_TOGGLE_SPINNER,
    };
};

export const close = () => {
    return {
        type: actionTypes.PRICE_LIST_MODAL_CLOSE,
    };
};

export const open = () => {
    return {
        type: actionTypes.PRICE_LIST_MODAL_OPEN,
    };
};

export const clear = () => {
    return {
        type: actionTypes.PRICE_LIST_CLEAR_FORM,
    };
};

export const updateError = (errorMessage) => {
    return {
        type: actionTypes.PRICE_LIST_UPDATE_ERROR_MESSAGE,
        payload: errorMessage
    };
};

export const updateFieldValue = (rowName, fieldName, fieldValue) => {
    return {
        type: actionTypes.PRICE_LIST_UPDATE_FIELD_VALUE,
        payload: { rowName, fieldName, fieldValue }
    };
};

export const enableSendEmailButton = (errorMessage) => {
    return {
        type: actionTypes.PRICE_LIST_ENABLE_SEND_EMAIL_BUTTON,
        payload: errorMessage
    };
};

export const updateFileInfo = (fileName, serverFilePath) => {
    return {
        type: actionTypes.PRICE_LIST_UPDATE_FILE_INFO,
        payload: { fileName: fileName, serverFilePath: serverFilePath }
    };
}

export const submitFormAndDownloadReport = (submitUrl, downloadUrl) => async (dispatch, getState) => {
    await dispatch(toggleSpinner());

    try {
        const postData = new FormData();
        const fieldRows = getState().priceListModalData.modalFormConfig;

        _.forEach(fieldRows, (fields, rowName) => {
            _.forEach(fields, (fieldProps, fieldName) => {
                const { value, dataType } = fieldProps;

                if (dataType == FORM_DATA_TYPES.DROPDOWN_MULTI) {
                    postData.append(fieldName, JSON.stringify(value));
                } else {
                    postData.append(fieldName, value);
                }
            })
        });

        const response = await ioms.post(submitUrl, postData, { withCredentials: true });
        const { res, message, fileName, serverFilePath, fieldsForUpdate } = response.data.result;

        if (res) {
            await dispatch(updateFileInfo(fileName, serverFilePath));
            await dispatch(downloadFileByPath(downloadUrl, serverFilePath, fileName));
            await dispatch(enableSendEmailButton());

            if (!_.isUndefined(fieldsForUpdate) && fieldsForUpdate) {
                await dispatch(updateFormAjax(fieldsForUpdate));
            }
        } else {
            await dispatch(updateError(message));
        }
    } catch (error) {
        await dispatch(updateError(error.message));
    }

    await dispatch(toggleSpinner());
}

export const sendEmail = (sendEmailUrl, postData) => async (dispatch) => {
    await dispatch(toggleSpinner());

    try {
        const response = await ioms.post(sendEmailUrl, postData, { withCredentials: true });
        const { res, message } = response.data.result;

        if (res) {
            await dispatch(clear());

            await dispatch(addMessage(MESSAGE_SUCCESS, { content: message, visible: true }));
        } else {
            dispatch(updateError(message));
        }
    } catch (error) {
        await dispatch(updateError(error.message));
    }

    await dispatch(toggleSpinner());
}

export const onChangeAjax = (url) => async (dispatch, getState) => {
    try {
        const postData = new FormData();
        const fieldRows = getState().priceListModalData.modalFormConfig;
        _.forEach(fieldRows, (fields, rowName) => {
            _.forEach(fields, (fieldProps, fieldName) => {
                const fieldValue = fieldProps.value;

                postData.append(fieldName, fieldValue);
            })
        });

        const response = await ioms.post(url, postData, { withCredentials: true });
        const { res, message, fieldsForUpdate } = response.data.result;

        if (res) {
            await dispatch({
                type: actionTypes.PRICE_LIST_UPDATE_FORM_AJAX,
                payload: fieldsForUpdate
            });
        } else {
            dispatch(updateError(message));
        }
    } catch (error) {
        await dispatch(updateError(error.message));
    }
}

export const updateFormAjax = (fieldsForUpdate) => {
    return {
        type: actionTypes.PRICE_LIST_UPDATE_FORM_AJAX,
        payload: fieldsForUpdate
    };
}

