import actionTypes from "./actionTypes";
import { addMessage } from '../messageActions';
import { MESSAGE_ERROR } from '../../helpers/const';
import { ROUTES } from '../../helpers/routes';
import ioms from "../../apis/ioms";
import history from "../../history";

export const fetchScreenData = (fetchUrl) => async (dispatch) => {
    try {
        await dispatch(toggleSpinner(true));

        const response = await ioms.get(fetchUrl, { withCredentials: true });
        const { success, messages, sessionExpired, closedUserLog, permission, shouldChangePassword, shouldAcceptTermsAndConditions } = response.data;

        if (success) {
            await dispatch(updateScreenData(response.data.result));
        } else {
            let route;
            let shouldAddErrorMessage = true;
            if (sessionExpired) {
                route = ROUTES.LOGIN_ROUTE.URL;
            } else if (closedUserLog) {
                route = ROUTES.LOGIN_ROUTE.URL;
            } else if (permission) {
                route = ROUTES.HOME_SCREEN_ROUTE.URL;
            } else if (shouldChangePassword) {
                route = ROUTES.EDIT_PASSWORD_ROUTE.URL;
            } else if (shouldAcceptTermsAndConditions) {
                route = ROUTES.TERMS_AND_CONDITIONS_DECISION_SCREEN.URL;
                shouldAddErrorMessage = false;
            }

            if (shouldAddErrorMessage) {
                await dispatch(addMessage(MESSAGE_ERROR, { content: messages[0].text, visible: true }));
            }

            history.push(route);
        }
    } catch (error) {
        await dispatch(addMessage(MESSAGE_ERROR, { content: error.message, visible: true }));
    }

    await dispatch(toggleSpinner(false));
};

export const updateScreenAjaxAction = (ajaxUrl, postData) => async (dispatch) => {
    try {
        const response = await ioms.post(ajaxUrl, postData, { withCredentials: true });

        const { success, result } = response.data;

        if (success) {
            await dispatch(updateScreenData(result));
        }
    } catch (error) {
        await dispatch(addMessage(MESSAGE_ERROR, { content: error.message, visible: true }));
    }
};

export const onLoadButtonClick = (loadUrl, postData) => async (dispatch) => {
    await dispatch(toggleSpinner(true));

    try {
        const response = await ioms.post(loadUrl, postData, { withCredentials: true });
        const { result, message } = response.data.result;

        if (result) {
            await dispatch(setIsAcitveResetButton(true));
            await dispatch(setIsAcitveLoadButton(false));

            await dispatch(updateScreenData(response.data.result));
        } else {
            await dispatch(addMessage(MESSAGE_ERROR, { content: message, visible: true }));
        }
    } catch (error) {
        await dispatch(addMessage(MESSAGE_ERROR, { content: error.message, visible: true }));
    }

    await dispatch(toggleSpinner(false));
}

export const updateScreenData = (screenData) => {
    return {
        type: actionTypes.TREE_PAGE_WITH_SIDE_PANE_UPDATE_SCREEN_DATA,
        payload: screenData
    };
};

export const toggleSpinner = (isActive) => {
    return {
        type: actionTypes.TREE_PAGE_WITH_SIDE_PANE_TOGGLE_SPINNER_IS_ACTIVE,
        payload: { isActive }
    };
};

export const setIsAcitveResetButton = (isActive) => {
    return {
        type: actionTypes.TREE_PAGE_WITH_SIDE_PANE_RESET_BTN_SET_IS_ACTIVE,
        payload: { isActive }
    };
};

export const setIsAcitveLoadButton = (isActive) => {
    return {
        type: actionTypes.TREE_PAGE_WITH_SIDE_PANE_LOAD_BTN_SET_IS_ACTIVE,
        payload: { isActive }
    };
};

export const setIsAcitveSelectorFields = (isActive) => {
    return {
        type: actionTypes.TREE_PAGE_WITH_SIDE_PANE_SELECTOR_FIELDS_SET_IS_ACTIVE,
        payload: { isActive }
    };
};

export const clearPage = (isActive) => {
    return {
        type: actionTypes.TREE_PAGE_WITH_SIDE_PANE_CLEAR,
        payload: { isActive }
    };
};

export const updateFilterFieldValue = (fieldName, fieldValue) => {
    return {
        type: actionTypes.TREE_PAGE_WITH_SIDE_PANE_FILTER_FIELD_UPDATE_VALUE,
        payload: { fieldName, fieldValue }
    }
}

export const setExpandYesAll = () => {
    return {
        type: actionTypes.TREE_PAGE_WITH_SIDE_PANE_EXPAND_YES_ALL,
    };
}

export const setExpandNoAll = () => {
    return {
        type: actionTypes.TREE_PAGE_WITH_SIDE_PANE_EXPAND_NO_ALL,
    };
}

export const toggleExpandOfNodeElem = (treeElementId) => {
    return {
        type: actionTypes.TREE_PAGE_WITH_SIDE_PANE_TOGGLE_EXPAND_NODE_ELEMENT,
        payload: { treeElementId }
    };
}

export const selectTreeRecord = (treeElementId, sidePaneInfo) => {
    return {
        type: actionTypes.TREE_PAGE_WITH_SIDE_PANE_SET_SELECTED_ELEMENT,
        payload: { treeElementId, sidePaneInfo }
    };
}
