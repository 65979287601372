import React from 'react';
import _ from 'lodash';
import { StatisticValue, StatisticLabel, Icon, Statistic } from 'semantic-ui-react';
import { StatisticProp } from '../../enum/apiResultProp/statisticGroup/StatisticProp';

export default ({ props, parentIsInverted }) => {
    const position = props[StatisticProp.Position];
    const labelText = props[StatisticProp.LabelText];
    const valueColor = props[StatisticProp.ValueColor];
    const valueText = props[StatisticProp.ValueText];
    const valueIsSmall = props[StatisticProp.ValueIsSmall];
    const valueIconName = props[StatisticProp.ValueIconName];

    const wrapTextAtWordBoundary = (text, maxLength) => {
        let words = text.split(/\s+/);
        let result = [];
        let currentLine = '';
    
        words.forEach(word => {
            if (currentLine.length + word.length > maxLength) {
                result.push(currentLine.trim());
    
                currentLine = word + ' ';
            } else {
                currentLine += word + ' ';
            }
        });
    
        result.push(currentLine.trim());
    
        return result;
    }

    const renderTextAtFewRows = (text, countOfSymbolsInARow) => {
        const valueRows = wrapTextAtWordBoundary(text, countOfSymbolsInARow);
        if (valueRows.length === 1) {
            return text;
        } else {
            return _.map(valueRows, (valueRowString, index) => {
                return (
                    <span key={index}>
                        {valueRowString}
                        <br />
                    </span >
                )
            });
        }
    }

    let statisticProps = valueColor ? { color: valueColor } : {};
    return (
        <Statistic inverted={parentIsInverted} {...statisticProps} key={position}>
            <StatisticValue text={valueIsSmall}>
                {valueIconName ? <Icon name={valueIconName} /> : null}{valueText ? renderTextAtFewRows(valueText, 15) : ''}
            </StatisticValue>
            <StatisticLabel style={{fontSize: '10px'}}>{renderTextAtFewRows(labelText, 25)}</StatisticLabel>
        </Statistic>
    );
}
