import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { List, Label, Button, TransitionablePortal, Modal, Popup } from 'semantic-ui-react';

import { toggleVisibility, fetch, refresh, readUnreadAction } from '../../actions/notificationBar/actions';
import { EMPTY_VALUE } from '../../helpers/const';
import { STRINGS } from '../../helpers/strings';
import { getValue } from '../../helpers/functions';
import history from '../../history';
import { ROUTES } from '../../helpers/routes';

class NotificationBar extends React.Component {
    intervalID;

    componentDidMount = () => {
        this.props.fetch();

        if (parseInt(process.env.REACT_APP_REFRESH_NOTIFICATIONS) === 1) {
            let refreshTime = this.props.settings.notificationRefreshMilliseconds ? this.props.settings.notificationRefreshMilliseconds : 5000;
            this.intervalID = setInterval(this.refresh, refreshTime);
        }
    }

    refresh = () => {
        const { refresh } = this.props.data;

        if (refresh) {
            this.props.refresh();
        } else {
            clearInterval(this.intervalID);
        }
    }

    onGoThereClick = async (route) => {
        await this.props.toggleVisibility();
        history.push(route);
    }

    render = () => {
        const { visible, notificationsList } = this.props.data;

        return (
            <TransitionablePortal
                open={visible}
                transition={{ animation: 'slide down', duration: 400 }}
                closeOnEscape={false}
                onOpen={this.refresh}
            >
                <Modal closeOnEscape={false} open={true} size='large'>
                    <Modal.Header as='h1' style={{ textAlign: 'center' }}>
                        Notifications
                    </Modal.Header>
                    <Modal.Content scrolling>
                        <List divided>
                            {_.map(notificationsList, (notification, id) => {
                                const { due_dt, created_dt, pastTime, title, message, created_by, readUnreadAction, readUnreadIcon, goThereAction } = notification;
                                const { readUnreadActionUrl, readUnreadActionLabel, readUnreadActionColor, readUnreadActionLoading } = readUnreadAction;
                                const { iconLabel, iconColor, iconName } = readUnreadIcon;
                                let { goThereLabel, goThereRouteName, goThereRouteParams } = goThereAction;

                                let route = ROUTES.HOME_SCREEN_ROUTE.URL;
                                if (goThereRouteName === -1000 || goThereRouteParams === -1000) {
                                    goThereLabel = 'Link not found';
                                } else {
                                    route = ROUTES[goThereRouteName].URL;
                                    if (goThereRouteParams !== EMPTY_VALUE) {
                                        _.forEach(goThereRouteParams, (value, name) => {
                                            route = route.replace(`:${name}`, value);
                                        })
                                    }
                                }

                                return (
                                    <List.Item key={id}>
                                        <List.Content floated='right'>
                                            <Button
                                                loading={readUnreadActionLoading}
                                                color={readUnreadActionColor}
                                                onClick={() => this.props.readUnreadAction(id, readUnreadActionUrl)}
                                            >
                                                {readUnreadActionLabel}
                                            </Button>
                                        </List.Content>
                                        <List.Content floated='right'>
                                            <Button primary onClick={() => this.onGoThereClick(route)}>
                                                {goThereLabel}
                                            </Button>
                                        </List.Content>

                                        <List.Content>
                                            <List.Header as='h4'>
                                                {title}
                                            </List.Header>
                                            <List.Description>
                                                <List.Icon name={iconName} color={iconColor} /><b>{iconLabel}</b>

                                                <Popup
                                                    content='Created By'
                                                    trigger={<List.Icon style={{ marginLeft: '1em' }} name='user' circular />}
                                                />
                                                <b>{created_by}</b>


                                                <Popup
                                                    content='Created DT'
                                                    trigger={<List.Icon style={{ marginLeft: '1em' }} color='green' name='clock' circular />}
                                                />
                                                {created_dt}


                                                <Popup
                                                    content='Time Elapsed (ddd::hh::mm)'
                                                    trigger={<List.Icon style={{ marginLeft: '1em' }} color='blue' name='clock' circular />}
                                                />
                                                {pastTime}

                                                <Popup
                                                    content='Deadline DT'
                                                    trigger={<List.Icon style={{ marginLeft: '1em' }} color='red' name='clock' circular />}
                                                />
                                                {due_dt}

                                            </List.Description>
                                            {message}
                                        </List.Content>
                                    </List.Item>
                                );
                            })}
                        </List>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='red' onClick={this.props.toggleVisibility}>
                            {getValue(this.props.translations, STRINGS.BUTTON_CLOSE)}
                        </Button>
                    </Modal.Actions>
                </Modal>
            </TransitionablePortal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        data: state.notificationBar,
        translations: state.auth.translations,
        settings: state.auth.settings,
    };
}

export default connect(mapStateToProps, { toggleVisibility, fetch, refresh, readUnreadAction })(NotificationBar);
