import React from 'react';
import { connect } from 'react-redux';
import { Router, Route } from 'react-router-dom';
import { Container, Segment } from 'semantic-ui-react';

import history from '../history';
import { ROUTES } from '../helpers/routes';
import '../resources/style/App.css';
import '../resources/style/NewScreen.css';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

import Header from '../components/Header';
import Login from '../components/Login';
import ForgotPassword from './ForgotPassword';
import ChangeEmail from './ChangeEmail';
import ChangePassword from './ChangePassword';
import Footer from './Footer';
import AppMessage from './AppMessage';

import NotificationBar from './customComponents/NotificationBar';
import GridPage from './pages/gridPage/GridPage';
import ReadPage from './pages/readPage/ReadPage';
import CreateEditPage from './pages/createEditPage/CreateEditPage';
import SysAdminPanel from './pages/SysAdminPanel';
import TreeNavigationWithStickySidePanePage from './pages/TreeNavigationWithStickySidePanePage';

import InactiveTimeModal from './customComponents/InactiveTimeModal';
import DocumentationModal from './customComponents/DocumentationModal';
import NewScreen from '../newComponents/NewScreen';
import NewDummyTable from '../newComponents/NewDummyTable';

class App extends React.Component {
    renderRoutes = () => {
        return (
            <Segment basic>
                <Route path={ROUTES.ROLE_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.ROLE_READ_PAGE_ROUTE.URL} exact component={ReadPage} />
                <Route path={ROUTES.ROLE_CREATE_PAGE_ROUTE.URL} exact component={CreateEditPage} />
                <Route path={ROUTES.ROLE_EDIT_PAGE_ROUTE.URL} exact component={CreateEditPage} />

                <Route path={ROUTES.LOGIN_ROUTE.URL} exact component={Login} />
                <Route path={ROUTES.FORGOT_PASSOWRD_ROUTE.URL} exact component={ForgotPassword} />
                <Route path={ROUTES.CHANGE_PASSOWRD_ROUTE.URL} exact component={ChangePassword} />
                <Route path={ROUTES.CHANGE_EMAIL_ROUTE.URL} exact component={ChangeEmail} />
                <Route path={ROUTES.SYS_ADMIN_PANEL_ROUTE.URL} exact component={SysAdminPanel} />
                <Route path={ROUTES.PROCEDURES_ROUTE.URL} exact component={SysAdminPanel} />

                <Route path={ROUTES.NAP_REPORT_SCREEN_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.INVOICE_GRID_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.INVOICE_READ_ROUTE.URL} exact component={NewScreen} />

                <Route path={ROUTES.MY_TEAM_INVOICE_GRID_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.MY_TEAM_INVOICE_READ_ROUTE.URL} exact component={NewScreen} />

                <Route path={ROUTES.VEHICLE_CATEGORY_GRID_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.VEHICLE_CATEGORY_READ_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.VEHICLE_CATEGORY_CREATE_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.VEHICLE_CATEGORY_EDIT_ROUTE.URL} exact component={NewScreen} />

                <Route path={ROUTES.VEHICLE_BRAND_GRID_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.VEHICLE_BRAND_READ_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.VEHICLE_BRAND_CREATE_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.VEHICLE_BRAND_EDIT_ROUTE.URL} exact component={NewScreen} />

                <Route path={ROUTES.HOME_SCREEN_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.TERMS_AND_CONDITIONS_DECISION_SCREEN.URL} exact component={NewScreen} />

                <Route path={ROUTES.INSURANCE_MANAGEMENT_GRID_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.RACER_CARD_MANAGEMENT_GRID_ROUTE.URL} exact component={NewScreen} />

                <Route path={ROUTES.TERMS_AND_CONDITIONS_GRID_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.TERMS_AND_CONDITIONS_READ_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.TERMS_AND_CONDITIONS_EDIT_ROUTE.URL} exact component={NewScreen} />

                <Route path={ROUTES.RACER_INFO_READ_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.RACER_INFO_EDIT_ROUTE.URL} exact component={NewScreen} />

                <Route path={ROUTES.EXTERNAL_USER_GRID_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.EXTERNAL_USER_READ_ROUTE.URL} exact component={NewScreen} />

                <Route path={ROUTES.RACER_LICENSE_REVIEW_GRID_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.RACER_LICENSE_REVIEW_SCREEN_ROUTE.URL} exact component={NewScreen} />

                <Route path={ROUTES.RACER_SEASON_GRID_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.RACER_SEASON_READ_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.RACER_SEASON_EDIT_ROUTE.URL} exact component={NewScreen} />

                <Route path={ROUTES.RACING_EVENT_GRID_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.RACING_EVENT_READ_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.RACING_EVENT_CREATE_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.RACING_EVENT_EDIT_ROUTE.URL} exact component={NewScreen} />

                <Route path={ROUTES.RACER_LICENSE_GRID_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.RACER_LICENSE_READ_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.RACER_LICENSE_READ_AFTER_PAYMENT_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.RACER_LICENSE_CREATE_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.RACER_LICENSE_EDIT_ROUTE.URL} exact component={NewScreen} />

                <Route path={ROUTES.AGE_GROUP_GRID_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.AGE_GROUP_READ_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.AGE_GROUP_CREATE_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.AGE_GROUP_EDIT_ROUTE.URL} exact component={NewScreen} />

                <Route path={ROUTES.INSURANCE_GROUP_GRID_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.INSURANCE_GROUP_READ_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.INSURANCE_GROUP_CREATE_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.INSURANCE_GROUP_EDIT_ROUTE.URL} exact component={NewScreen} />

                <Route path={ROUTES.RACING_TEAM_GRID_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.RACING_TEAM_READ_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.RACING_TEAM_CREATE_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.RACING_TEAM_EDIT_ROUTE.URL} exact component={NewScreen} />

                <Route path={ROUTES.MY_RACING_TEAM_GRID_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.MY_RACING_TEAM_READ_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.MY_RACING_TEAM_READ_AFTER_PAYMENT_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.MY_RACING_TEAM_CREATE_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.MY_RACING_TEAM_EDIT_ROUTE.URL} exact component={NewScreen} />

                <Route path={ROUTES.INTERNAL_USER_GRID_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.INTERNAL_USER_READ_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.INTERNAL_USER_CREATE_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.INTERNAL_USER_EDIT_ROUTE.URL} exact component={NewScreen} />

                <Route path={ROUTES.DISCIPLINE_GRID_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.DISCIPLINE_READ_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.DISCIPLINE_CREATE_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.DISCIPLINE_EDIT_ROUTE.URL} exact component={NewScreen} />

                <Route path={ROUTES.RACING_NUMBER_MANAGEMENT_GRID_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.RACING_NUMBER_MANAGEMENT_CREATE_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.MY_RACERS_LICENSE_REPORT_GRID_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.MY_RACERS_PAYMENT_MANAGEMENT_GRID_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.MY_RACERS_PAYMENT_MANAGEMENT_AFTER_PAYMENT_GRID_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.MY_RACERS_INSURANCE_REPORT_GRID_ROUTE.URL} exact component={NewScreen} />
                
                <Route path={ROUTES.ALL_RACERS_LICENSE_REPORT_GRID_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.ALL_RACERS_LICENSE_REPORT_DETAIL_ROUTE.URL} exact component={NewScreen} />

                <Route path={ROUTES.SEASON_GRID_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.SEASON_READ_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.SEASON_CREATE_ROUTE.URL} exact component={NewScreen} />
                <Route path={ROUTES.SEASON_EDIT_ROUTE.URL} exact component={NewScreen} />
            </Segment>
        );
    }

    renderNotificationBar = () => {
        const isLoggedIn = this.props.isLoggedIn;

        if (isLoggedIn) {
            return <NotificationBar />;
        } else {
            return null;
        }
    }

    renderIdleTimeModal = () => {
        if (parseInt(process.env.REACT_APP_AUTO_LOGOUT_FUNCTIONALITY) === 0) {
            return null;
        }

        const isLoggedIn = this.props.isLoggedIn;
        if (isLoggedIn) {
            return <InactiveTimeModal />;
        } else {
            return null;
        }
    };

    render() {
        return (
            <Router history={history}>
                <div className="App Site">
                    <div className="Site-content">
                        <Header />

                        {this.renderIdleTimeModal()}

                        {this.renderNotificationBar()}

                        <AppMessage />

                        <DocumentationModal />

                        <Container fluid>
                            {this.renderRoutes()}
                        </Container>
                    </div>

                    <Footer version={this.props.version} />
                </div>
            </Router>
        );
    }
}

const mapStateToProps = (state) => {
    const { isLoggedIn, settings } = state.auth;
    const { version } = settings;

    return { isLoggedIn, version };
}

export default connect(mapStateToProps, {})(App);
